import React from "react";

function Sale() {
  return (
    <div className="container-fluid relative md:mt-24 mt-16">
      <div className="grid grid-cols-1">
        <div className="relative overflow-hidden py-24 px-4 md:px-10 bg-primary  bg-center bg-no-repeat bg-cover bg-[url('https://shreethemes.in/cartzio/layouts/assets/images/hero/bg3.png')]">
          <div className="absolute inset-0  bg-center bg-no-repeat bg-cover bg-[url('https://shreethemes.in/cartzio/layouts/assets/images/hero/bg-shape.png')]"></div>
          <div className="grid grid-cols-1 justify-center text-center relative z-1">
            <h3 className="text-4xl leading-normal tracking-wide font-bold text-white">
              End of Season Clearance <br /> Sale upto 30%
            </h3>
            <div id="countdown" className="mt-6">
              <ul className="count-down list-none inline-block space-x-1">
                <li
                  id="days"
                  className="text-[28px] leading-[72px] h-[80px] w-[80px] font-medium rounded-md shadow shadow-gray-100 inline-block text-center text-white"
                >
                  141<p className="count-head">Days</p>{" "}
                </li>
                <li
                  id="hours"
                  className="text-[28px] leading-[72px] h-[80px] w-[80px] font-medium rounded-md shadow shadow-gray-100 inline-block text-center text-white"
                >
                  3<p className="count-head">Hours</p>{" "}
                </li>
                <li
                  id="mins"
                  className="text-[28px] leading-[72px] h-[80px] w-[80px] font-medium rounded-md shadow shadow-gray-100 inline-block text-center text-white"
                >
                  30<p className="count-head">Mins</p>{" "}
                </li>
                <li
                  id="secs"
                  className="text-[28px] leading-[72px] h-[80px] w-[80px] font-medium rounded-md shadow shadow-gray-100 inline-block text-center text-white"
                >
                  50<p className="count-head">Secs</p>{" "}
                </li>
                <li id="end" className="h1"></li>
              </ul>
            </div>
            <div className="mt-4">
              <a
                href="sale.html"
                className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-white text-orange-500 rounded-md"
              >
                <i className="mdi mdi-cart-outline"></i> Shop Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sale;
