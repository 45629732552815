import React from "react";

const PopularItem = [
  {
    popularImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/luxurious-bag2.jpg",
    itemName: "Orange Luxurious Bag",
    itemNewPrice: "₹16.00",
    itemOldPrice: "₹21.00",
  },
  {
    popularImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/luxurious-bag2.jpg",
    itemName: "Orange Luxurious Bag",
    itemNewPrice: "₹16.00",
    itemOldPrice: "₹21.00",
  },
  {
    popularImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/luxurious-bag2.jpg",
    itemName: "Orange Luxurious Bag",
    itemNewPrice: "₹16.00",
    itemOldPrice: "₹21.00",
  },
  {
    popularImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/luxurious-bag2.jpg",
    itemName: "Orange Luxurious Bag",
    itemNewPrice: "₹16.00",
    itemOldPrice: "₹21.00",
  },
  {
    popularImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/luxurious-bag2.jpg",
    itemName: "Orange Luxurious Bag",
    itemNewPrice: "₹16.00",
    itemOldPrice: "₹21.00",
  },
];

function PopularItems() {
  return (
    <div>
      <div class="grid items-end md:grid-cols-2 mb-6">
        <div class="md:text-start text-center">
          <h5 class="font-semibold text-3xl leading-normal mb-4">
            Popular Items
          </h5>
          <p class="text-slate-400 max-w-xl">Popular items in this week</p>
        </div>

        <div class="md:text-end">
          <a href="shop-grid.html" class="text-slate-400 hover:text-orange-500">
            See More Items <i class="mdi mdi-arrow-right"></i>
          </a>
        </div>
      </div>

      <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
        {PopularItem.map((item) => (
          <div class="group">
            <div class="relative overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
              <img
                src={item.popularImg}
                class="group-hover:scale-110 duration-500"
                alt=""
              />

              <div class="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
                <a
                  href="shop-cart.html"
                  class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md"
                >
                  Add to Cart
                </a>
              </div>

              <ul class="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                <li>
                  <a
                    href="javascript:void(0)"
                    class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-heart size-4"
                    >
                      <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                    </svg>
                  </a>
                </li>
                <li class="mt-1">
                  <a
                    href="shop-item-detail.html"
                    class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-eye size-4"
                    >
                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                  </a>
                </li>
                <li class="mt-1">
                  <a
                    href="javascript:void(0)"
                    class="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-bookmark size-4"
                    >
                      <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                    </svg>
                  </a>
                </li>
              </ul>

              <ul class="list-none absolute top-[10px] start-4">
                <li>
                  <a
                    href="javascript:void(0)"
                    class="bg-red-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5"
                  >
                    New
                  </a>
                </li>
              </ul>
            </div>

            <div class="mt-4">
              <a
                href="product-detail-one.html"
                class="hover:text-orange-500 text-lg font-medium"
              >
                {item.itemName}
              </a>
              <div class="flex justify-between items-center mt-1">
                <p>
                  {item.itemNewPrice}{" "}
                  <del class="text-slate-400">{item.itemOldPrice}</del>
                </p>
                <ul class="font-medium text-amber-400 list-none">
                  <li class="inline">
                    <i class="mdi mdi-star"></i>
                  </li>
                  <li class="inline">
                    <i class="mdi mdi-star"></i>
                  </li>
                  <li class="inline">
                    <i class="mdi mdi-star"></i>
                  </li>
                  <li class="inline">
                    <i class="mdi mdi-star"></i>
                  </li>
                  <li class="inline">
                    <i class="mdi mdi-star"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PopularItems;
