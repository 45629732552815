import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import signIn from "../../firebase/auth/signin"; // Adjust the path as per your authentication logic
import { useAuthContext } from "../../Context/AuthContext";
import AuthPage from "../../Reusable/AuthPages/AuthPage";

const AuthForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { user } = useAuthContext();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (username.trim() === "" || password.trim() === "") {
      setError("Please enter both username and password.");
      return;
    }

    try {
      await signIn(username, password); // Implement signIn function according to your authentication logic
      alert(`Logged in as ${username}`);
      history.goBack(); // Navigate to previous page
    } catch (error) {
      setError("Invalid username or password.");
    }
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  console.log(user);
  React.useEffect(() => {
    if (user) history.goBack();
  }, [user, history]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="font-semibold" htmlFor="LoginEmail">
          Email Address:
        </label>
        <input
          id="LoginEmail"
          type="email"
          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
          placeholder="name@example.com"
          value={username}
          onChange={handleUsernameChange}
        />
      </div>

      <div className="mb-4">
        <label className="font-semibold" htmlFor="LoginPassword">
          Password:
        </label>
        <input
          id="LoginPassword"
          type="password"
          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
          placeholder="Password:"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      <div className="mb-4">
        <input
          type="submit"
          className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
          value="Login / Sign in"
        />
      </div>

      <div className="text-center">
        <span className="text-slate-400 me-2">Don't have an account ?</span>
        <Link
          to="/signup"
          className="text-black dark:text-white font-bold inline-block"
        >
          Sign Up
        </Link>
      </div>
    </form>
  );
};

function LogIn() {
  return (
    <>
      <AuthPage
        AuthImage="https://shreethemes.in/cartzio/layouts/assets/images/login.jpg"
        AuthForm={<AuthForm />}
      />
    </>
  );
}

export default LogIn;
