import React from "react";

function AuthPage({ AuthImage, AuthForm }) {
  return (
    <>
      <section class="md:h-screen py-36 flex items-center bg-orange-500/10 dark:bg-orange-500/20 bg-[url('https://shreethemes.in/cartzio/layouts/assets/images/hero/bg-shape.png')] bg-center bg-no-repeat bg-cover">
        <div class="container relative">
          <div class="grid grid-cols-1">
            <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
              <div class="grid md:grid-cols-2 grid-cols-1 items-center">
                <div class="relative md:shrink-0">
                  <img
                    class="h-full w-full object-cover md:h-[44rem]"
                    src={AuthImage}
                    alt=""
                  />
                </div>

                <div class="p-8 lg:px-20">
                  <div class="text-center">
                    <a href="index.html">
                      <img
                        src="https://shreethemes.in/cartzio/layouts/assets/images/logo-dark.png"
                        class="mx-auto block dark:hidden"
                        alt=""
                      />
                      <img
                        src="https://shreethemes.in/cartzio/layouts/assets/images/logo-light.png"
                        class="mx-auto hidden dark:block"
                        alt=""
                      />
                    </a>
                  </div>
                  <div class="text-start lg:py-20 py-8">
                    <div class="grid grid-cols-1">{AuthForm}</div>
                  </div>

                  <div class="text-center">
                    <p class="mb-0 text-slate-400">
                      ©<script>document.write(new Date().getFullYear())</script>
                      2024 Cartzio. Design with
                      <i class="mdi mdi-heart text-red-600"></i> by
                      <a
                        href="https://shreethemes.in/"
                        target="_blank"
                        class="text-reset"
                      >
                        Shreethemes
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AuthPage;
