import { ChevronRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { Link } from "react-router-dom";
interface BreadcrumbItem {
  label: string;
  path: string;
}

interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbItem[];
}
const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  return (
    <>
      <nav className="container capitalize relative py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
        <ol className="breadcrumb flex gap-2">
          {breadcrumbs.map((breadcrumb, index) => {
            const isLast = index === breadcrumbs.length - 1;
            return (
              <li
                key={breadcrumb.path}
                className="inline-block  font-bold duration-500 ease-in-out hover:text-orange-500 dark:text-white"
                aria-current={isLast ? "page" : undefined}
              >
                {isLast ? (
                  <p className="text-orange-600">{breadcrumb.label}</p>
                ) : (
                  <div className="flex items-center gap-2">
                    <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
                    <ChevronRightIcon className="h-3 w-3" />
                  </div>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumbs;
