import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Stretched.png";
// import CartIconComp from "./CartIconComp";
// import AccountIconComp from "./AccountIconComp";
// import LoginIconComp from "./LoginIconComp";
// import SearchBox from "./SearchBox";
import { useAuthContext } from "../../../Context/AuthContext";
import SearchBox from "../SearchBox";
import LoginIconComp from "../LoginIconComp";
import AccountIconComp from "../AccountIconComp";
import NavBarDownWeb from "./NavbarDownWeb";
import { ShoppingBagIcon } from "@heroicons/react/24/solid";

const NavbarWeb = () => {
  const { user } = useAuthContext();
  console.log(user);
  let isLogin = false;
  if (user) {
    isLogin = true;
  }

  return (
    <header className=" bg-primary hidden md:block">
      <div className="container max-w-6xl mx-auto gap-3">
        <div className="flex justify-between p-4 items-center">
          <Link classname="logo-image p-4 m-4" to="/">
            <img
              src={logo}
              alt="Picture of the author"
              className="h-12 w-auto"
            />
          </Link>
          <div className="searchbox flex-1 mx-4 max-w-md">
            <SearchBox />
          </div>
          <div className="profile_and_cart flex justify-end gap-3">
            <div className="cartIcon">
              <Link to="/cart">
                <button
                  class="inline-flex w-full gap-2 justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                  type="button"
                >
                  Cart
                  <ShoppingBagIcon className="h-4 w-4" />
                </button>
              </Link>
            </div>

            {isLogin ? (
              <div className="profileIcon">{<AccountIconComp />}</div>
            ) : (
              <div className="loginIcon">{<LoginIconComp />}</div>
            )}
          </div>
        </div>
        <NavBarDownWeb />
      </div>
    </header>
  );
};

export default NavbarWeb;
