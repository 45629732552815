import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { getDocuments } from "../firebase/getData";
import { ProductType } from "../types";

interface ProductsContextType {
  products: ProductType[] | null;
  fetchProducts: () => void;
}

const ProductsContext = createContext<ProductsContextType>({
  products: null,
  fetchProducts: () => {},
});

interface ProductsProviderProps {
  children: ReactNode;
}

const ProductsProvider: React.FC<ProductsProviderProps> = ({ children }) => {
  const [products, setProducts] = useState<ProductType[] | null>(null);

  async function fetchProducts() {
    const { result, error } = await getDocuments("products");
    if (error) {
      console.log("error in getting the products in products context");
    } else if (result) {
      setProducts(result);
    }
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <ProductsContext.Provider value={{ products, fetchProducts }}>
      {children}
    </ProductsContext.Provider>
  );
};

const useProductsContext = (): ProductsContextType => {
  const context = useContext(ProductsContext);
  if (!context) {
    throw new Error(
      "useProductsContext must be used within a ProductsProvider"
    );
  }
  return context;
};

export { ProductsProvider, useProductsContext };
