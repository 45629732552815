import { useState, useEffect } from "react";

import Popover from "./Mobile/PopoverMobile";

const BrandDropdown = (props) => {
  const { brands } = props;

  return (
    <div className="flex gap-3">
      <Popover color="black" name={"Shop by Brands"} options={brands} />
    </div>
  );
};
export default BrandDropdown;
