import { useEffect, useState } from "react";
import { useAuthContext } from "../../Context/AuthContext";
import { Timestamp } from "firebase/firestore";
import { OrderTypeWithId } from "../../types";
import { getDocumentsbyFilter } from "../../firebase/getData";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const OrdersList = () => {
  const [orders, setOrders] = useState<OrderTypeWithId[]>([]);
  const { user }: any = useAuthContext();

  useEffect(() => {
    async function getOrdersOfUser() {
      const uid = user.uid;
      const phoneNumber = user.phoneNumber;
      const email = user.email;

      // Create queries
      const { result: resultByUid, error: err1 } = await getDocumentsbyFilter(
        "orders",
        "user.uid",
        uid
      );
      try {
        const { result: resultByPhoneNumber, error: err2 } =
          await getDocumentsbyFilter("orders", "user.phoneNumber", phoneNumber);
        const { result: resultByEmail, error: err3 } =
          await getDocumentsbyFilter("orders", "user.email", email);
        if (err1 || err2 || err3) {
          throw new Error("Can't fetch Order");
        }
        const ordersMap = new Map<string, OrderTypeWithId>();
        if (resultByUid) {
          resultByUid.forEach((doc) => {
            ordersMap.set(doc.id, doc as OrderTypeWithId);
          });
        }
        if (resultByPhoneNumber) {
          resultByPhoneNumber.forEach((doc) => {
            ordersMap.set(doc.id, doc as OrderTypeWithId);
          });
        }
        if (resultByEmail) {
          resultByEmail.forEach((doc) => {
            ordersMap.set(doc.id, doc as OrderTypeWithId);
          });
        }

        // Convert the Map to an array of orders and return
        const finalOrders = Array.from(ordersMap.values());
        const sortedFinalOrders = finalOrders.sort((a, b) => {
          const dateA =
            a.createdAt instanceof Timestamp
              ? a.createdAt.toDate()
              : a.createdAt;
          const dateB =
            b.createdAt instanceof Timestamp
              ? b.createdAt.toDate()
              : b.createdAt;

          return dateB.getTime() - dateA.getTime(); // For ascending order; swap dateA and dateB for descending
        });
        setOrders(finalOrders);
      } catch (error) {
        toast.error("Failed to fetch orders");
      }
    }
    getOrdersOfUser();
  }, []);
  console.log(orders);
  return (
    <div className="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
      <table className="w-full text-start text-slate-500 dark:text-slate-400">
        <thead className="text-sm uppercase bg-slate-50 dark:bg-slate-800">
          <tr className="text-start">
            <th scope="col" className="px-2 py-3 text-start">
              Order no.
            </th>
            <th scope="col" className="px-2 py-3 text-start">
              Date
            </th>
            <th scope="col" className="px-2 py-3 text-start">
              Status
            </th>
            <th scope="col" className="px-2 py-3 text-start">
              Total
            </th>
            <th scope="col" className="px-2 py-3 text-start">
              Quantity
            </th>
            <th scope="col" className="px-2 py-3 text-start">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            const date =
              order.createdAt instanceof Timestamp
                ? order.createdAt.toDate()
                : order.createdAt;
            const formattedDate = date.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
            let totalSP = 0;
            let totalMRP = 0;
            let totalQuantity = 0;
            order.cart.forEach((item) => {
              let itemTotalSP = item.quantity * +item.SP;
              let itemTotalMRP = item.quantity * +item.MRP;
              totalSP += itemTotalSP;
              totalMRP += itemTotalMRP;
              totalQuantity += item.quantity;
            });
            return (
              <tr className="bg-white dark:bg-slate-900 text-start">
                <th className="px-2 py-3 text-start" scope="row">
                  {order.id}
                </th>
                <td className="px-2 py-3 text-start">{formattedDate}</td>
                <td className="px-2 py-3 text-start text-green-600">
                  {order.currentStatus}
                </td>
                <td className="px-2 py-3 text-start">
                  Rs {order.netPaymentAmount}
                </td>
                <td className="px-2 py-3 text-start">{totalQuantity}</td>
                <td className="px-2 py-3 text-start">
                  <Link to={`/order/${order.id}`} className="text-orange-500">
                    View <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersList;
