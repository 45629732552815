import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  UserCircleIcon,
  UserIcon,
  TableCellsIcon,
  ShoppingBagIcon,
  ArrowLeftStartOnRectangleIcon,
} from "@heroicons/react/24/solid";
import logout from "../../firebase/auth/logout";

export default function AccountIconComp() {
  const [showItems, setShowItems] = useState(false);
  async function handleLogout() {
    console.log("logging Out");
    logout();
  }

  return (
    <div
      className="relative inline-block text-left z-50"
      onMouseEnter={() => setShowItems(true)}
      onMouseLeave={() => setShowItems(false)}
    >
      <button className="inline-flex w-full justify-center rounded-md bg-black bg-opacity-20 px-2 sm:px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        Account
        {/* Placeholder for User Icon */}
        <UserCircleIcon className="ml-2 -mr-1 h-5 w-5 text-white" />
      </button>

      {showItems && (
        <div className="absolute right-0 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Link to="/account">
            <div className="px-1 py-1">
              <button className="bg-white text-orange-600 group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-white">
                {/* Placeholder for Profile Icon */}
                <UserIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                <span className="text-black">My Profile</span>
              </button>
              <button className="bg-white text-orange-600 group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-primary hover:text-white">
                {/* Placeholder for Orders Icon */}
                <TableCellsIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                <span className="text-black">My Orders</span>
              </button>
            </div>
          </Link>

          <div className="px-1 py-1">
            {/* Additional menu items here */}
            <button
              className="bg-white text-orange-600 group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-gray-200"
              onClick={handleLogout}
            >
              {/* Placeholder for Logout Icon */}
              <ArrowLeftStartOnRectangleIcon
                className="mr-2 h-5 w-5"
                aria-hidden="true"
              />
              <span className="text-black">Logout</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
