import React from 'react'

function MobileApp() {
    return (
        <div className="container relative md:mt-24 mt-16">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center">
                <div className="lg:col-span-5 md:col-span-6">
                    <img src="https://shreethemes.in/cartzio/layouts/assets/images/envelope.svg" className="mx-auto d-block" alt="" />
                </div>
                <div className="lg:col-span-7 md:col-span-6">
                    <span className="bg-orange-500/5 text-orange-500 text-xs font-bold px-2.5 py-0.5 rounded h-5">Mobile Apps</span>
                    <h4 className="font-semibold text-3xl leading-normal my-4">Available for your <br /> Smartphones</h4>
                    <p className="text-slate-400 max-w-xl mb-0">Upgrade your style with our curated sets. Choose confidence, embrace your unique look.</p>
                    <div className="my-5">
                        <a href=""><img src="https://shreethemes.in/cartzio/layouts/assets/images/app.png" className="m-1 inline-block" alt="" /></a>
                        <a href=""><img src="https://shreethemes.in/cartzio/layouts/assets/images/playstore.png" className="m-1 inline-block" alt="" /></a>
                    </div>

                    <div className="inline-block">
                        <div className="pt-4 flex items-center border-t border-gray-100 dark:border-gray-800">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-smartphone me-2 text-orange-500 h-10 w-10"><rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect><line x1="12" y1="18" x2="12.01" y2="18"></line></svg>
                            <div className="content">
                                <h6 className="text-base font-medium">Install app now on your cellphones</h6>
                                <a href="" className="text-orange-500">Learn More <i className="mdi mdi-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>



    )
}

export default MobileApp