import React from "react";
import { useAuthContext } from "../../Context/AuthContext";
import { AddressItemType } from "../../types";
import { useHistory } from "react-router-dom";

interface AddressFormProps {
  currentAddress: AddressItemType;
  setCurrentAddress: React.Dispatch<React.SetStateAction<AddressItemType>>;
  isNewAddress: Boolean;
  setIsNewAddress: React.Dispatch<React.SetStateAction<Boolean>>;
  handlePlaceOrder: () => void;
}

const AddressForm: React.FC<AddressFormProps> = ({
  currentAddress,
  setCurrentAddress,
  isNewAddress,
  setIsNewAddress,
  handlePlaceOrder,
}) => {
  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCurrentAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };
  const history = useHistory();

  const { user }: any = useAuthContext();
  // React.useEffect(() => {
  //   if (user) history.goBack();
  // }, [user, history]);

  return (
    <div className="lg:col-span-8">
      <div className="p-6 rounded-md shadow dark:shadow-gray-800">
        <div className="flex justify-between">
          <h3 className="text-xl leading-normal font-semibold">
            Billing address
          </h3>
          {/*************FOR NOW LET USER CREATE A NEW ADDRESS *************/}
          {/* <button
            type="button"
            onClick={() => null}
            className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center top-[2px] end-[3px] h-[46px] bg-orange-500 text-white rounded-full"
          >
            Create New
          </button> */}
        </div>

        {isNewAddress ? (
          <form>
            <div className="grid lg:grid-cols-12 grid-cols-1 mt-6 gap-5">
              <div className="lg:col-span-6">
                <label className="form-label font-semibold">
                  Name : <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  required
                  className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                  placeholder="Name:"
                  id="name"
                  name="name"
                  value={currentAddress.name}
                  onChange={handleAddressChange}
                />
              </div>

              <div className="lg:col-span-6">
                <label className="form-label font-semibold">
                  Phone No. : <span className="text-red-600">*</span>
                </label>
                <input
                  type="number"
                  className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                  placeholder="Phone No.:"
                  id="lastname"
                  name="phoneNumber"
                  value={currentAddress.phoneNumber}
                  onChange={handleAddressChange}
                />
              </div>

              {/* <div className="lg:col-span-6">
            <label className="form-label font-semibold">
              Username
            </label>
            <div className="relative mt-2">
              <span
                className="absolute top-0.5 start-0.5 w-9 h-9 text-xl bg-gray-100 dark:bg-slate-800 inline-flex justify-center items-center text-dark dark:text-white rounded"
                id="basic-addon1"
              >
                <i className="mdi mdi-at"></i>
              </span>
              <input
                type="text"
                className="ps-12 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                placeholder="Username"
              />
            </div>
          </div>

          <div className="lg:col-span-6">
            <label className="form-label font-semibold">
              Your Email : <span className="text-red-600">*</span>
            </label>
            <input
              type="email"
              className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
              placeholder="Email"
              name="email"
            />
          </div> */}

              <div className="lg:col-span-12">
                <label className="form-label font-semibold">
                  Address : <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                  placeholder="Address:"
                  id="address"
                  name="addressLine1"
                  value={currentAddress.addressLine1}
                  onChange={handleAddressChange}
                />
              </div>

              <div className="lg:col-span-12">
                <label className="form-label font-semibold">Address 2 : </label>
                <input
                  type="text"
                  className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                  placeholder="Address:"
                  id="address"
                  name="addressLine2"
                  value={currentAddress.addressLine2}
                  onChange={handleAddressChange}
                />
              </div>

              {/* <div className="lg:col-span-4">
            <label className="font-semibold">Country:</label>
            <select className="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0">
              <option value="USA">USA</option>
              <option value="CAD">Canada</option>
              <option value="CHINA">China</option>
            </select>
          </div> */}
              {/* // DROPDOWN */}

              {/* <div className="lg:col-span-4">
            <label className="font-semibold">State:</label>
            <select className="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0">
              <option value="CAL">California</option>
              <option value="TEX">Texas</option>
              <option value="FLOR">Florida</option>
            </select>
          </div> */}

              <div className="lg:col-span-4">
                <label className="form-label font-semibold">
                  State : <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                  placeholder="State:"
                  id="state"
                  name="state"
                  value={currentAddress.state}
                  onChange={handleAddressChange}
                />
              </div>
              <div className="lg:col-span-4">
                <label className="form-label font-semibold">
                  City : <span className="text-red-600">*</span>
                </label>
                <input
                  type="text"
                  className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                  placeholder="City:"
                  id="city"
                  name="city"
                  value={currentAddress.city}
                  onChange={handleAddressChange}
                />
              </div>

              <div className="lg:col-span-4">
                <label className="form-label font-semibold">
                  PIN Code : <span className="text-red-600">*</span>
                </label>
                <input
                  type="number"
                  className="w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0 mt-2"
                  placeholder="PIN Code:"
                  id="postalCode"
                  name="postalCode"
                  value={currentAddress.postalCode}
                  onChange={handleAddressChange}
                />
              </div>

              <div className="lg:col-span-12">
                <div className="flex items-center w-full mb-0">
                  <input
                    className="form-checkbox rounded border-gray-100 dark:border-gray-800 text-orange-500 focus:border-orange-300 focus:ring focus:ring-offset-0 focus:ring-orange-200 focus:ring-opacity-50 me-2"
                    type="checkbox"
                    value=""
                    id="sameaddress"
                  />
                  <label className="form-check-label text-slate-400">
                    Shipping address is the same as my billing address
                  </label>
                </div>

                <div className="flex items-center w-full mb-0">
                  <input
                    className="form-checkbox rounded border-gray-100 dark:border-gray-800 text-orange-500 focus:border-orange-300 focus:ring focus:ring-offset-0 focus:ring-orange-200 focus:ring-opacity-50 me-2"
                    type="checkbox"
                    value=""
                    id="savenexttime"
                  />
                  <label className="form-check-label text-slate-400">
                    Save this information for next time
                  </label>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <>
            <div>Select Address</div>
          </>
        )}

        {user ? (
          <div className="mt-4 flex gap-4">
            <button
              type="button"
              onClick={handlePlaceOrder}
              className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
            >
              Place Order
            </button>
          </div>
        ) : (
          <div className="mt-4 flex gap-4">
            <button
              type="button"
              onClick={handlePlaceOrder}
              className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
            >
              Continue as Guest
            </button>
            <button
              type="submit"
              className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
            >
              Login
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressForm;
