import React from "react";
import { useMetaDataContext } from "../../Context/MetaDataContext";

const collection = [
  {
    productImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/categories/mens-ware.jpg",
    productName: "Mens Wear",
  },
  {
    productImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/categories/mens-ware.jpg",
    productName: "Ladies Wear",
  },
  {
    productImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/categories/mens-ware.jpg",
    productName: "Kids Wear",
  },
  {
    productImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/categories/mens-ware.jpg",
    productName: "Smart Watch",
  },
  {
    productImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/categories/mens-ware.jpg",
    productName: "Sunglasses",
  },
];

function HomePage() {
  const { metaData } = useMetaDataContext();
  console.warn(metaData);
  let brands = [];
  let categories = [];
  const brandsObj = metaData.find((i) => i.id === "brands");
  if (brandsObj) {
    brands = brandsObj.brands;
  }
  const categoriesObj = metaData.find((i) => i.id === "categories");
  if (categoriesObj) {
    categories = categoriesObj.categories;
  }
  console.warn(categories);
  return (
    <div className="relative md:py-24 py-16">
      <div className="grid grid-cols-1 justify-center text-center mb-6">
        <h5 className="font-semibold text-3xl leading-normal mb-4">
          Shop The Collections
        </h5>
        <p className="text-slate-400 max-w-xl mx-auto">
          Shop the latest products from the most popular collections
        </p>
      </div>
      <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
        {categories.map((collections) => {
          return (
            <div
              className="text-center hover:text-orange-500 cursor-pointer"
              style={{ width: "100%" }}
            >
              <div className="aspect-square">
                <img
                  src={
                    collections.imageUrl ||
                    "https://shreethemes.in/cartzio/layouts/assets/images/categories/mens-ware.jpg"
                  }
                  className="rounded-full shadow-2xl dark:shadow-gray-800 object-contain"
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>

              <span className="text-xl font-medium mt-3 block">
                {collections.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HomePage;
