import React from "react";
import MyAccount from "../../Reusable/MyAccount/MyAccount";

function UserAccount() {
  return (
    <>
      <MyAccount
        userAccountSteps={
          <>
            <h5 className="text-lg font-semibold mb-6">My Orders</h5>
            <div className="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
              <table className="w-full text-start text-slate-500 dark:text-slate-400">
                <thead className="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                  <tr className="text-start">
                    <th scope="col" className="px-2 py-3 text-start">
                      Order no.
                    </th>
                    <th scope="col" className="px-2 py-3 text-start">
                      Date
                    </th>
                    <th scope="col" className="px-2 py-3 text-start">
                      Status
                    </th>
                    <th scope="col" className="px-2 py-3 text-start">
                      Total
                    </th>
                    <th scope="col" className="px-2 py-3 text-start">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white dark:bg-slate-900 text-start">
                    <th className="px-2 py-3 text-start" scope="row">
                      7107
                    </th>
                    <td className="px-2 py-3 text-start">1st November 2021</td>
                    <td className="px-2 py-3 text-start text-green-600">
                      Delivered
                    </td>
                    <td className="px-2 py-3 text-start">
                      ₹ 320 <span className="text-slate-400">for 2items</span>
                    </td>
                    <td className="px-2 py-3 text-start">
                      <a href="javascript:void(0)" className="text-orange-500">
                        View <i className="mdi mdi-chevron-right"></i>
                      </a>
                    </td>
                  </tr>

                  <tr className="bg-white dark:bg-slate-900 text-start border-t border-gray-100 dark:border-gray-700">
                    <th className="px-2 py-3 text-start" scope="row">
                      8007
                    </th>
                    <td className="px-2 py-3 text-start">4th November 2021</td>
                    <td className="px-2 py-3 text-start text-slate-400">
                      Processing
                    </td>
                    <td className="px-2 py-3 text-start">
                      ₹ 800 <span className="text-slate-400">for 1item</span>
                    </td>
                    <td className="px-2 py-3 text-start">
                      <a href="javascript:void(0)" className="text-orange-500">
                        View <i className="mdi mdi-chevron-right"></i>
                      </a>
                    </td>
                  </tr>

                  <tr className="bg-white dark:bg-slate-900 text-start border-t border-gray-100 dark:border-gray-700">
                    <th className="px-2 py-3 text-start" scope="row">
                      8008
                    </th>
                    <td className="px-2 py-3 text-start">4th November 2021</td>
                    <td className="px-2 py-3 text-start text-red-600">
                      Canceled
                    </td>
                    <td className="px-2 py-3 text-start">
                      ₹ 800 <span className="text-slate-400">for 1item</span>
                    </td>
                    <td className="px-2 py-3 text-start">
                      <a href="javascript:void(0)" className="text-orange-500">
                        View <i className="mdi mdi-chevron-right"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h5 className="text-lg font-semibold my-6">My favourite Items</h5>

            <div className="rounded-md shadow dark:shadow-gray-800 p-6">
              <ul>
                <li className="flex justify-between items-center pb-6">
                  <div className="flex items-center">
                    <img
                      src="https://shreethemes.in/cartzio/layouts/assets/images/shop/apple-smart-watch.jpg"
                      className="rounded shadow dark:shadow-gray-800 w-16"
                      alt=""
                    />

                    <div className="ms-4">
                      <a
                        href=""
                        className="font-semibold hover:text-orange-500"
                      >
                        Apple Smart Watch
                      </a>
                      <p className="text-green-600 text-sm mt-1">
                        ₹16.00 <del className="text-red-600">₹21.00</del>
                      </p>
                    </div>
                  </div>

                  <div>
                    <a
                      href=""
                      className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-red-600/5 hover:bg-red-600 text-red-600 hover:text-white rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-trash-2 h-4 w-4"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    </a>
                  </div>
                </li>

                <li className="flex justify-between items-center py-6 border-t border-gray-100 dark:border-gray-700">
                  <div className="flex items-center">
                    <img
                      src="https://shreethemes.in/cartzio/layouts/assets/images/shop/ladies-2.jpg"
                      className="rounded shadow dark:shadow-gray-800 w-16"
                      alt=""
                    />

                    <div className="ms-4">
                      <a
                        href=""
                        className="font-semibold hover:text-orange-500"
                      >
                        Ladies Top
                      </a>
                      <p className="text-green-600 text-sm mt-1">
                        ₹16.00 <del className="text-red-600">₹21.00</del>
                      </p>
                    </div>
                  </div>

                  <div>
                    <a
                      href=""
                      className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-red-600/5 hover:bg-red-600 text-red-600 hover:text-white rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-trash-2 h-4 w-4"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </>
        }
      />
    </>
  );
}

export default UserAccount;
