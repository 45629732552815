import React from 'react'
import HomePage from './HomePage'
import NewArrival from './NewArrival'
import PopularItems from './PopularItems'
import Sale from './Sale'
// import HomeContainer1 from "../Container/HomeContainer1"

export default function HomeScreen1() {
  return (
    <>
      {/* <HomeContainer1/> */}

      <section class="relative md:flex table w-full items-center md:h-screen py-36 bg-emerald-500/5 bg-[url('https://shreethemes.in/cartzio/layouts/assets/images/hero/bg3.png')] md:bg-top bg-center bg-no-repeat bg-cover">
        <div class="container relative">
          <div class="grid grid-cols-1 justify-center">
            <div class="text-center">
              <span class="uppercase font-semibold text-lg">New Collection</span>
              <h4 class="md:text-6xl text-4xl md:leading-normal leading-normal font-bold my-3">The Gift Suite</h4>
              <p class="text-lg">Our latest collection of essential basics.</p>

              <div class="mt-6">
                <a href="" class="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center bg-slate-900 dark:bg-orange-500 text-white rounded-md">Shop Now <i class="mdi mdi-arrow-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </section>



      <div className='container mx-auto'>
        <HomePage />
        <NewArrival />
      </div>
      <Sale />
      <div className='container mx-auto'>
        <PopularItems />
      </div>

    </>
  )
}
