import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Stretched.png";
import { useAuthContext } from "../../../Context/AuthContext";
import LoginIconComp from "../LoginIconComp";
import AccountIconComp from "../AccountIconComp";
import {
  Bars3Icon,
  XMarkIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline"; // Importing icons for menu toggle
import SideBar from "./Sidebar";

const NavbarMobile = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage sidebar visibility
  const { user } = useAuthContext();

  let isLogin = !!user; // Simplified conditional check

  return (
    <div>
      {/* Mobile view Menu button */}
      <div className="md:hidden p-4 flex justify-between bg-primary items-center">
        <div className="flex gap-2">
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <XMarkIcon className="h-7 w-7 text-secondary" />
            ) : (
              <Bars3Icon className="h-7 w-7  text-secondary" />
            )}
          </button>
          <Link classname="logo-image p-2 m-2" to="/">
            <img src={logo} alt="Picture of the author" height={"20"} />
          </Link>
        </div>

        <div className="profile_and_cart flex justify-end gap-3">
          <div className="cartIcon">
            {/* Updated to use Link from 'react-router-dom' */}
            <Link to="/cart">
              <button
                className="inline-flex w-full gap-1 justify-center rounded-md bg-black bg-opacity-20 px-2 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                type="button"
              >
                <span className="">Cart</span>
                <ShoppingCartIcon className="h-5 w-5" />
                {/* SVG for cart icon */}
              </button>
            </Link>
          </div>
          {isLogin ? <AccountIconComp /> : <LoginIconComp />}
        </div>
      </div>

      {/* Sidebar / Navbar */}
      <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />
      {/* <div
        className={`${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transform top-0 left-0 w-64 bg-primary fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 md:relative md:translate-x-0 md:w-auto md:bg-transparent`}
      >
         Mobile Sidebar Content 
        <div className="md:hidden">
          <SearchBox />
          <NavBarDown />
        </div>
      </div> */}

      {/* Overlay to close the sidebar when clicked */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-20"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default NavbarMobile;
