import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, getFirestore, Timestamp } from "firebase/firestore";
import { OrderType, OrderTypeWithId } from "../types";
import OrderDetails from "../Container/OrderDetails/OrderDetails";
import getDocument from "../firebase/getData";

const OrderDetailScreen: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [order, setOrder] = useState<OrderTypeWithId | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const db = getFirestore();

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "orders", orderId);
        const docSnap = await getDoc(docRef);
        const { result, error } = await getDocument("orders", orderId);

        if (result) {
          const data = result as OrderTypeWithId;
          // Convert Timestamps to Dates
          data.createdAt =
            data.createdAt instanceof Timestamp
              ? data.createdAt.toDate()
              : data.createdAt;
          data.statusHistory = data.statusHistory.map((item) => ({
            ...item,
            time:
              item.time instanceof Timestamp ? item.time.toDate() : item.time,
          }));

          setOrder(data);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
      }
      setLoading(false);
    };

    fetchOrder();
  }, [orderId]);

  if (loading) return <div>Loading...</div>;
  if (!order) return <div>No order found</div>;

  // Format date for display
  const formattedDate = order.createdAt.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  });

  return (
    <div>
      <h2>Order Details</h2>
      {/* Display order details here */}
      <p>Order ID: {orderId}</p>
      <p>Order Created On: {formattedDate}</p>
      {/* Map through order.cart, order.statusHistory, etc. to display other details */}
      <OrderDetails order={order} />
    </div>
  );
};

export default OrderDetailScreen;
