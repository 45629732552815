import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./logo.svg";
// import CartIconComp from "./CartIconComp";
// import AccountIconComp from "./AccountIconComp";
// import LoginIconComp from "./LoginIconComp";
// import SearchBox from "./SearchBox";
import { useAuthContext } from "../../Context/AuthContext";
// import NavbarMobile from "./Mobile/NavbarMobile";
import NavbarWeb from "./web/NavbarWeb";
import NavbarMobile from "./Mobile/NavbarMobile";

const Navbar = () => {
  const { user } = useAuthContext();
  console.log(user);
  let isLogin = false;
  if (user) {
    isLogin = true;
  }

  return (
    <>
      <div className="">
        <NavbarMobile />
      </div>
      <div className="">
        <NavbarWeb />
      </div>
    </>
  );
};

export default Navbar;
