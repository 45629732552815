import { useEffect, useState } from "react";
import { useCartContext } from "../../Context/CartContext";
import useCouponCode from "../../hooks/useCouponCode";

const CouponCodeArea = ({
  couponCode,
  setCouponCode,
  shippingCharges,
  setShippingCharges,
}: any) => {
  const { cart, dispatch } = useCartContext();
  const { fetchPromoCode, couponDetails, couponError, couponLoading } =
    useCouponCode();
  let totalSP = 0;
  let totalMRP = 0;
  let totalQuantity = 0;
  cart.forEach((item) => {
    let itemTotalSP = item.quantity * +item.SP;
    let itemTotalMRP = item.quantity * +item.MRP;
    totalSP += itemTotalSP;
    totalMRP += itemTotalMRP;
    totalQuantity += item.quantity;
  });
  let netPayable = +totalSP + shippingCharges - +couponCode.couponDiscount;
  const [code, setCode] = useState("");

  async function validateCoupon() {
    await fetchPromoCode(code);
  }
  useEffect(() => {
    console.log({ couponDetails, couponError });
    if (couponError) {
      setCouponCode({
        coupon: "",
        couponDiscount: "",
        couponType: "",
        isAppled: false,
      });
    } else if (couponDetails) {
      const { code, discountPercent, maxDiscount } = couponDetails;
      const calculatedDiscount = ((+discountPercent * +totalSP) / 100).toFixed(
        2
      );
      const discountRs = Math.min(+calculatedDiscount, +maxDiscount);
      setCouponCode({
        coupon: code,
        couponDiscount: discountRs,
        couponType: "PROMO",
        isAppled: true,
      });
    }
  }, [couponDetails, couponError]);

  useEffect(() => {
    if (+totalSP > 500) {
      setShippingCharges(0);
    } else {
      setShippingCharges(90);
    }
  }, [cart]);

  return (
    <div className="lg:col-span-4">
      <div className="p-6 rounded-md shadow dark:shadow-gray-800">
        <div className="flex justify-between items-center">
          <h5 className="text-lg font-semibold">Your Cart</h5>

          <a
            href="javascript:void(0)"
            className="bg-orange-500 flex justify-center items-center text-white text-[10px] font-bold px-2.5 py-0.5 rounded-full h-5"
          >
            {totalQuantity}
          </a>
        </div>

        <div className="mt-4 rounded-md shadow dark:shadow-gray-800">
          <div className="p-3 flex justify-between items-center">
            <div>
              <h5 className="font-semibold">TotalValue</h5>
              <p className="text-sm text-slate-400">Net Order Value</p>
            </div>

            <p className="text-slate-400 font-semibold">₹ {totalSP}</p>
          </div>
          <div className="p-3 flex justify-between items-center">
            <div>
              <h5 className="font-semibold">Shipping Charges</h5>
              <p className="text-sm text-slate-400">
                Free delivery on 500 order
              </p>
            </div>

            <p className="text-slate-400 font-semibold">₹ {shippingCharges}</p>
          </div>

          {couponCode.isAppled && (
            <div className="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800 bg-gray-50 dark:bg-slate-800 text-green-600">
              <div>
                <h5 className="font-semibold">Promo code</h5>
                <p className="text-sm text-green-600">
                  {couponCode.coupon.toUpperCase()} - Applied
                </p>
              </div>

              <p className="text-red-600 font-semibold">
                -₹ {couponCode.couponDiscount}
              </p>
            </div>
          )}
          <div className="p-3 flex justify-between items-center border border-gray-100 dark:border-gray-800">
            <div>
              <h5 className="font-semibold">Total (USD)</h5>
            </div>

            <p className="font-semibold">₹ {netPayable}</p>
          </div>
        </div>

        <div className="subcribe-form mt-6">
          <form className="relative max-w-xl">
            <input
              id="subcribe"
              value={code}
              onChange={(e) => setCode(e.target.value.toUpperCase())}
              className="py-4 pe-40 ps-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800"
              placeholder="Promo code"
            />
            <button
              type="button"
              onClick={validateCoupon}
              disabled={couponLoading}
              className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-orange-500 text-white rounded-full"
            >
              Validate
            </button>
          </form>
        </div>
        <p className="p-3 text-red-600">{couponError}</p>
      </div>
    </div>
  );
};

export default CouponCodeArea;
