import NavBarDownMobile from "./NavbarDownMobile";
import SearchBox from "../SearchBox";

const SideBar = (props) => {
  const { isOpen, setIsOpen } = props;
  return (
    <>
      <div
        className={`${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transform top-0 left-0 w-64 bg-primary fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 md:relative md:translate-x-0 md:w-auto md:bg-transparent`}
      >
        {/* Mobile Sidebar Content */}
        <div className="md:hidden py-3 px-1">
          <SearchBox />
          <NavBarDownMobile setIsOpen={setIsOpen} />
        </div>
      </div>
    </>
  );
};

export default SideBar;
