import React from 'react'
import AuthPage from '../../Reusable/AuthPages/AuthPage'

function ForgotPassword() {
    return (
        <>
            <AuthPage AuthImage="https://shreethemes.in/cartzio/layouts/assets/images/forgot-password.jpg"
                AuthForm={
                    <>
                        <p class="text-slate-400 mb-6">Please enter your email address. You will receive a link to create a new password via email.</p>
                        <div class="grid grid-cols-1">
                            <div class="mb-4">
                                <label class="font-semibold" for="LoginEmail">Email Address:</label>
                                <input id="LoginEmail" type="email" class="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="name@example.com" />
                            </div>

                            <div class="mb-4">
                                <input type="submit" class="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full" value="Send" />
                            </div>

                            <div class="text-center">
                                <span class="text-slate-400 me-2">Remember your password ? </span><a href="login.html" class="text-black dark:text-white font-bold inline-block">Sign in</a>
                            </div>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default ForgotPassword