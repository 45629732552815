import React, { useEffect, useState } from "react";
import signUp from "../../firebase/auth/signup"; // Adjust the path as per your authentication logic
import AuthPage from "../../Reusable/AuthPages/AuthPage";
import { toast } from "react-toastify";

const AuthForm = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!name || !email || !password) {
      setError("Please fill out all fields.");
      return;
    }
    if (phoneNumber.length != 10 || phoneNumber.length !== 0) {
      setError("Please enter 10 digits phone number");
    }
    if (!acceptTerms) {
      setError("Please accept the terms and conditions.");
      return;
    }

    try {
      // Call your signUp function with name, email, and password
      const { error, result } = await signUp(
        name,
        email,
        password,
        phoneNumber
      );
      if (error) setError(error);
      else {
        alert("Registration successful!");
      }
      // You can redirect the user to another page upon successful registration
    } catch (error) {
      setError("An error occurred during registration.");
    }
  };
  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "bottom-right",
      });
    }
  }, [error]);
  console.log(error);
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="font-semibold" htmlFor="RegisterName">
          Your Name:
        </label>
        <input
          id="RegisterName"
          type="text"
          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
          placeholder="Harry"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="font-semibold" htmlFor="RegisterName">
          Phone Number:
        </label>
        <input
          id="phoneNumber"
          type="number"
          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
          value={phoneNumber}
          placeholder="Enter 10 Digits Phone Number"
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label className="font-semibold" htmlFor="LoginEmail">
          Email Address:
        </label>
        <input
          id="LoginEmail"
          type="email"
          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
          placeholder="name@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label className="font-semibold" htmlFor="LoginPassword">
          Password:
        </label>
        <input
          id="LoginPassword"
          type="password"
          className="mt-3 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
          placeholder="Password:"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <div className="flex items-center w-full mb-0">
          <input
            className="form-checkbox rounded border-gray-100 dark:border-gray-800 text-orange-500 focus:border-orange-300 focus:ring focus:ring-offset-0 focus:ring-orange-200 focus:ring-opacity-50 me-2"
            type="checkbox"
            value={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
            id="AcceptT&C"
          />
          <label
            className="form-check-label text-slate-400"
            htmlFor="AcceptT&C"
          >
            I Accept{" "}
            <a href="" className="text-orange-500">
              Terms And Condition
            </a>
          </label>
        </div>
      </div>

      {error && <div className="text-red-500 mb-4">**{error}</div>}

      <div className="mb-4">
        <input
          type="submit"
          className="py-2 px-5 inline-block tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md w-full"
          value="Register"
        />
      </div>

      <div className="text-center">
        <span className="text-slate-400 me-2">Already have an account ? </span>{" "}
        <a
          href="login.html"
          className="text-black dark:text-white font-bold inline-block"
        >
          Sign in
        </a>
      </div>
    </form>
  );
};

function SignUp() {
  return (
    <>
      <AuthPage
        AuthImage="https://shreethemes.in/cartzio/layouts/assets/images/forgot-password.jpg"
        AuthForm={<AuthForm />}
      />
    </>
  );
}

export default SignUp;
