import React, { useEffect, useState } from "react";
import { useCartContext } from "../../Context/CartContext";
import AddressForm from "./AddressForm";
import CouponCodeArea from "./CouponCodeArea";
import useCreateOrder from "../../hooks/useCreateOrder";
import { CustomerType } from "../../types";
import { toast } from "react-toastify";
import { useAuthContext } from "../../Context/AuthContext";
import { getDocumentsbyFilter } from "../../firebase/getData";

export type AddressItemType = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  id: string;
  name: string;
  phoneNumber: string;
  postalCode: string;
  state: string;
};

function Checkout() {
  const { cart, dispatch } = useCartContext();
  const { user }: any = useAuthContext();

  const { createOrder, error, loading } = useCreateOrder();

  const [currentAddress, setCurrentAddress] = useState<AddressItemType>({
    addressLine1: "",
    addressLine2: "",
    city: "",
    id: "",
    name: "",
    phoneNumber: "",
    postalCode: "",
    state: "",
  });
  const [isNewAddress, setIsNewAddress] = useState<Boolean>(true);

  const [couponCode, setCouponCode] = useState({
    coupon: "",
    couponDiscount: "",
    couponType: "",
    isAppled: false,
  });
  let [shippingCharges, setShippingCharges] = useState(100);

  useEffect(() => {
    if (error) {
      console.log({ error });
      toast.error(error, { position: "bottom-right" });
    }
  }, [error]);

  async function handlePlaceOrder() {
    const totalDiscount = couponCode.couponDiscount;
    let totalSP = 0;
    let totalMRP = 0;
    let totalQuantity = 0;
    cart.forEach((item) => {
      let itemTotalSP = item.quantity * +item.SP;
      let itemTotalMRP = item.quantity * +item.MRP;
      totalSP += itemTotalSP;
      totalMRP += itemTotalMRP;
      totalQuantity += item.quantity;
    });
    let netPayable = +totalSP + shippingCharges - +couponCode.couponDiscount;
    function AfterOrderFxn() {
      console.log("palced");
      toast.success("Order Placed");
    }
    let shippingChargesNo = shippingCharges.toString();
    let customer = {
      email: "null@null.com",
      name: currentAddress.name,
      phoneNumber: currentAddress.phoneNumber,
      uid: "",
    };
    if (user) {
      const { result: resultByUid, error } = await getDocumentsbyFilter(
        "users",
        "id",
        user.uid
      );
      if (resultByUid && resultByUid.length) {
        customer = {
          email: resultByUid[0].email || "",
          name: resultByUid[0].name || "",
          phoneNumber: resultByUid[0].phoneNumber || "",
          uid: resultByUid[0].email || "",
        };
      } else {
        const { result: resultByPhoneNumber, error } =
          await getDocumentsbyFilter("users", "phoneNumber", user.phoneNumber);
        if (resultByPhoneNumber && resultByPhoneNumber.length) {
          customer = {
            email: resultByPhoneNumber[0].email || "",
            name: resultByPhoneNumber[0].name || "",
            phoneNumber: resultByPhoneNumber[0].phoneNumber || "",
            uid: resultByPhoneNumber[0].email || "",
          };
        } else {
          const { result: resultByEmail, error } = await getDocumentsbyFilter(
            "users",
            "email",
            user.email
          );
          if (resultByEmail && resultByEmail.length) {
            customer = {
              email: resultByEmail[0].email || "",
              name: resultByEmail[0].name || "",
              phoneNumber: resultByEmail[0].phoneNumber || "",
              uid: resultByEmail[0].email || "",
            };
          }
        }
      }
    }

    createOrder(
      cart,
      customer,
      currentAddress,
      totalDiscount,
      shippingChargesNo,
      totalSP.toString(),
      netPayable.toString(),
      AfterOrderFxn
    );
  }

  return (
    <>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-6">
            <AddressForm
              setCurrentAddress={setCurrentAddress}
              currentAddress={currentAddress}
              isNewAddress={isNewAddress}
              setIsNewAddress={setIsNewAddress}
              handlePlaceOrder={handlePlaceOrder}
            />

            <CouponCodeArea
              couponCode={couponCode}
              setCouponCode={setCouponCode}
              shippingCharges={shippingCharges}
              setShippingCharges={setShippingCharges}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Checkout;
