import { useState, useEffect } from "react";
import {
  getFirestore,
  doc,
  getDoc,
  DocumentSnapshot,
} from "firebase/firestore/lite";
import { getDocuments, getDocumentsbyFilter } from "../firebase/getData";

export interface PromoCode {
  code: string;
  discountPercent: string;
  maxDiscount: string;
}

const useCouponCode = () => {
  const [couponDetails, setCouponDetails] = useState<PromoCode | null>(null);
  const [couponError, setCouponError] = useState<string | null>(null);
  const [couponLoading, setCouponLoading] = useState<boolean>(false);

  const db = getFirestore();

  const fetchPromoCode = async (code: string) => {
    const searchCode = code.toLowerCase();
    if (!searchCode) return;
    setCouponDetails(null);
    setCouponError(null);
    setCouponLoading(true);
    console.log(searchCode);
    try {
      const { error, result } = await getDocumentsbyFilter(
        "promoCodes",
        "code",
        searchCode
      );
      console.log({ error, result });
      if (error) {
        setCouponDetails(null);
        setCouponError("Error Fetching Code");
        setCouponLoading(false);
      }
      if (result && result.length) {
        const codeDetails = result[0];
        setCouponDetails(codeDetails);
        setCouponError(null);
        setCouponLoading(false);
      } else {
        setCouponDetails(null);
        setCouponError("Enter a valid Code");
        setCouponLoading(false);
      }
    } catch (e) {
      setCouponDetails(null);
      setCouponError("Error Fetching Code");
      setCouponLoading(false);
    }
  };

  return { couponDetails, fetchPromoCode, couponError, couponLoading };
};

export default useCouponCode;
