import {
  EnvelopeIcon,
  MapPinIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import React from "react";

const OrderDetails: React.FC<{
  order: import("../../types").OrderTypeWithId;
}> = ({ order }) => {
  console.log(order);
  const formattedDate = order.createdAt.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  });
  return (
    <section className="relative lg:py-24 py-16 bg-slate-50 dark:bg-slate-800">
      <div className="container relative">
        <div className="md:flex justify-center mt-24">
          <div className="lg:w-4/5 w-full">
            <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
              <div className="border-b border-gray-100 dark:border-gray-700 pb-6">
                <div className="md:flex justify-between">
                  <div>
                    <img
                      src="https://shreethemes.in/cartzio/layouts/assets/images/logo-dark.png"
                      className="block dark:hidden"
                      alt=""
                    />
                    <img
                      src="https://shreethemes.in/cartzio/layouts/assets/images/logo-light.png"
                      className="hidden dark:block"
                      alt=""
                    />
                    <div className="flex mt-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-link h-4 w-4 me-3 mt-1"
                      >
                        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                      </svg>
                      <a
                        href="https://1.envato.market/cartzio"
                        target="_blank"
                        className="text-orange-500 dark:text-white font-medium"
                      >
                        https://1.envato.market/cartzio
                      </a>
                    </div>
                  </div>

                  <div className="mt-6 md:mt-0 md:w-56">
                    <h5 className="text-lg font-semibold">Address:</h5>

                    <ul className="list-none">
                      <li className="flex mt-3">
                        <MapPinIcon className="h-5 w-5 me-3 mt-1" />

                        <a
                          href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                          data-type="iframe"
                          className="lightbox text-slate-400"
                        >
                          1419 Riverwood Drive, <br /> Redding, CA 96001
                        </a>
                      </li>

                      <li className="flex mt-3">
                        <EnvelopeIcon className="h-5 w-5 me-3 mt-1" />
                        <a
                          href="mailto:contact@example.com"
                          className="text-slate-400"
                        >
                          info@cartzio.com
                        </a>
                      </li>

                      <li className="flex mt-3">
                        <PhoneIcon className="h-5 w-5 me-3 mt-1" />
                        <a
                          href="tel:+152534-468-854"
                          className="text-slate-400"
                        >
                          (+12) 1546-456-856
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="md:flex justify-between">
                <div className="mt-6">
                  <h5 className="text-lg font-semibold">Invoice Details :</h5>

                  <ul className="list-none">
                    <li className="flex mt-3">
                      <span className="w-24">Invoice No. :</span>
                      <span className="text-slate-400">{order.id}</span>
                    </li>

                    <li className="flex mt-3">
                      <span className="w-24">Name :</span>
                      <span className="text-slate-400">
                        {order.currentAddress.name}
                      </span>
                    </li>

                    <li className="flex mt-3">
                      <span className="w-24">Address :</span>
                      <span className="text-slate-400">
                        1962 Pike Street, <br /> Diego, CA 92123
                      </span>
                    </li>

                    <li className="flex mt-3">
                      <span className="w-24">Phone :</span>
                      <span className="text-slate-400">
                        {order.currentAddress.phoneNumber}
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="mt-3 md:w-56">
                  <ul className="list-none">
                    <li className="flex mt-3">
                      <span className="w-24">Date :</span>
                      <span className="text-slate-400">{formattedDate}</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md mt-6">
                <table className="w-full text-start text-slate-500 dark:text-slate-400">
                  <thead className="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                    <tr>
                      <th scope="col" className="text-center px-6 py-3 w-16">
                        No.
                      </th>
                      <th scope="col" className="text-start px-6 py-3">
                        Items
                      </th>
                      <th scope="col" className="text-center px-6 py-3 w-20">
                        Qty
                      </th>
                      <th scope="col" className="text-center px-6 py-3 w-28">
                        Rate(₹)
                      </th>
                      <th scope="col" className="text-end px-6 py-3 w-20">
                        Total(₹)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.cart.map((item, index) => {
                      const itemPrice = +item.quantity * +item.SP;
                      return (
                        <>
                          <tr className="bg-white dark:bg-slate-900">
                            <td className="text-center px-6 py-4">
                              {index + 1}
                            </td>
                            <th
                              scope="row"
                              className="text-start px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                            >
                              {item.name}
                            </th>
                            <td className="text-center px-6 py-4">
                              {item.quantity}
                            </td>
                            <td className="text-center px-6 py-4">{item.SP}</td>
                            <td className="text-end px-6 py-4">
                              $ {itemPrice}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className="w-56 ms-auto p-5">
                <ul className="list-none">
                  <li className="text-slate-400 flex justify-between">
                    <span>Subtotal :</span>
                    <span>₹ 520</span>
                  </li>
                  <li className="text-slate-400 flex justify-between mt-2">
                    <span>Shipping :</span>
                    <span>+₹ 20</span>
                  </li>
                  <li className="text-slate-400 flex justify-between mt-2">
                    <span>
                      <div>Discount :</div>
                    </span>
                    <span>-₹ {order.totalDiscount || 0}</span>
                  </li>
                  <li className="flex justify-between font-semibold mt-2">
                    <span>Total :</span>
                    <span>₹ {order.netPaymentAmount}</span>
                  </li>
                </ul>
              </div>

              <div className="invoice-footer border-t border-gray-100 dark:border-gray-700 pt-6">
                <div className="md:flex justify-between">
                  <div>
                    <div className="text-slate-400 text-center md:text-start">
                      <h6 className="mb-0">
                        Customer Services :{" "}
                        <a
                          href="tel:+152534-468-854"
                          className="text-amber-500"
                        >
                          (+12) 1546-456-856
                        </a>
                      </h6>
                    </div>
                  </div>

                  <div className="mt-4 md:mt-0">
                    <div className="text-slate-400 text-center md:text-end">
                      <h6 className="mb-0">
                        <a
                          href="page-terms.html"
                          target="_blank"
                          className="text-orange-500"
                        >
                          Terms &amp; Conditions
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderDetails;
