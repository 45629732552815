import addData from "../addData";
import firebase_app from "../config";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

const auth = getAuth(firebase_app);

export default async function signUp(name, email, password, phoneNumber) {
  let result = null,
    error = null;
  try {
    console.log(auth);
    result = await createUserWithEmailAndPassword(auth, email, password);
    const parts = name.trim().split(/\s+/);
    console.log("result", result);
    const firstName = parts[0];

    const lastName = parts.slice(1).join(" ");

    let searchTerm = `${name} ${phoneNumber} ${email}`;
    const userCollectionData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      addresses: [],
      createdOn: new Date(),
      displayName: name,
      creationMethod: "LOGIN",
      id: result.user.uid,
      searchTerm,
    };
    await addData("users", userCollectionData);
    console.log(result);
  } catch (e) {
    console.log(e.message);
    if (e.message === "Firebase: Error (auth/email-already-in-use).") {
      error = "Email already in use";
    } else if (e.message === "Firebase: Error (auth/invalid-email).") {
      error = "Please enter a Valid Email";
    } else if (
      e.message ===
      "Firebase: Password should be at least 6 characters (auth/weak-password)."
    ) {
      error = "Password should be at least 6 characters- Weak Password";
    } else if (e?.message) error = e.message;
    else error = "Something went wrong";
  }

  return { result, error };
}
