const FavItems = () => {
  return (
    <div className="rounded-md shadow dark:shadow-gray-800 p-6">
      <ul>
        <li className="flex justify-between items-center pb-6">
          <div className="flex items-center">
            <img
              src="https://shreethemes.in/cartzio/layouts/assets/images/shop/apple-smart-watch.jpg"
              className="rounded shadow dark:shadow-gray-800 w-16"
              alt=""
            />

            <div className="ms-4">
              <a href="" className="font-semibold hover:text-orange-500">
                Apple Smart Watch
              </a>
              <p className="text-green-600 text-sm mt-1">
                $16.00 <del className="text-red-600">$21.00</del>
              </p>
            </div>
          </div>

          <div>
            <a
              href=""
              className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-red-600/5 hover:bg-red-600 text-red-600 hover:text-white rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-trash-2 h-4 w-4"
              >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </a>
          </div>
        </li>

        <li className="flex justify-between items-center py-6 border-t border-gray-100 dark:border-gray-700">
          <div className="flex items-center">
            <img
              src="https://shreethemes.in/cartzio/layouts/assets/images/shop/ladies-2.jpg"
              className="rounded shadow dark:shadow-gray-800 w-16"
              alt=""
            />

            <div className="ms-4">
              <a href="" className="font-semibold hover:text-orange-500">
                Ladies Top
              </a>
              <p className="text-green-600 text-sm mt-1">
                $16.00 <del className="text-red-600">$21.00</del>
              </p>
            </div>
          </div>

          <div>
            <a
              href=""
              className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-red-600/5 hover:bg-red-600 text-red-600 hover:text-white rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-trash-2 h-4 w-4"
              >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </a>
          </div>
        </li>

        <li className="flex justify-between items-center pt-6 border-t border-gray-100 dark:border-gray-700">
          <div className="flex items-center">
            <img
              src="https://shreethemes.in/cartzio/layouts/assets/images/shop/ladies-sun-glasses.jpg"
              className="rounded shadow dark:shadow-gray-800 w-16"
              alt=""
            />

            <div className="ms-4">
              <a href="" className="font-semibold hover:text-orange-500">
                Ladies Sunglasses
              </a>
              <p className="text-green-600 text-sm mt-1">
                $16.00 <del className="text-red-600">$21.00</del>
              </p>
            </div>
          </div>

          <div>
            <a
              href=""
              className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center bg-red-600/5 hover:bg-red-600 text-red-600 hover:text-white rounded-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-trash-2 h-4 w-4"
              >
                <polyline points="3 6 5 6 21 6"></polyline>
                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                <line x1="10" y1="11" x2="10" y2="17"></line>
                <line x1="14" y1="11" x2="14" y2="17"></line>
              </svg>
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default FavItems;
