import React, { useEffect, useState } from "react";
import SideNav from "./SideNav";
import FavItems from "./FavItems";
import OrdersList from "./OrdersList";
import { useAuthContext } from "../../Context/AuthContext";
import { useHistory } from "react-router-dom";

const MyAccount: React.FC = () => {
  const { user }: any = useAuthContext();
  const history = useHistory();

  // Check if the user is not present and redirect to login
  if (!user) {
    // This will redirect to the /login route if the user is not found
    history.push("/login");
    return null; // Or you could return a placeholder/loading component while the redirect is being processed
  }
  return (
    <section className="relative lg:pb-24 pb-16 md:mt-[84px] mt-[70px]">
      <div className="md:container container-fluid relative">
        <div
          className="relative overflow-hidden md:rounded-md shadow dark:shadow-gray-700 h-52  bg-center bg-no-repeat bg-cover"
          style={{
            backgroundImage:
              "url('https://shreethemes.in/cartzio/layouts/assets/images/hero/pages.jpg')",
          }}
        ></div>
      </div>

      <div className="container relative md:mt-24 mt-16">
        <div className="md:flex">
          <SideNav />

          <div className="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0">
            <h5 className="text-lg font-semibold mb-6">My Orders</h5>
            <OrdersList />

            <h5 className="text-lg font-semibold my-6">My favourite Items</h5>

            <FavItems />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyAccount;
