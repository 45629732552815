import React, { useEffect, useState } from "react";
import { useProductsContext } from "../Context/ProductsContext";
import { ProductType, VariantType, SubVariantType } from "../types";
import Product from "../Container/Product/Product";
import { useHistory, useLocation } from "react-router-dom";

const ProductScreen: React.FC<{ match: any }> = ({ match }) => {
  const { products, fetchProducts } = useProductsContext();
  const [product, setProduct] = useState<ProductType | null>(null);
  const [variant, setVariant] = useState<VariantType | null>(null);
  const [subVariant, setSubVariant] = useState<SubVariantType | null>(null);
  const { productId } = match.params;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const variantParams = queryParams.get("variant");
  const subVariantParams = queryParams.get("subVariant");
  const history = useHistory();

  useEffect(() => {
    // Fetch products if not already fetched
    if (!products) {
      fetchProducts();
    } else {
      // Find the product with matching productId
      const foundProduct = products.find(
        (p: ProductType) => p.id === productId
      );
      if (foundProduct) {
        console.log(foundProduct);
        setProduct(foundProduct);
        // Set variant and subVariant based on query parameters or product data
        if (variantParams) {
          const selectedVariant = foundProduct.variants.find(
            (v) => v.value === variantParams
          );
          if (selectedVariant) {
            setVariant(selectedVariant);
            if (subVariantParams) {
              let selectedSubVariant = selectedVariant.subvariants.find(
                (sv) => {
                  return sv.name === subVariantParams;
                }
              );
              if (selectedSubVariant) setSubVariant(selectedSubVariant);
              else {
                let selectedSubVariant = selectedVariant.subvariants[0];
                setSubVariant(selectedSubVariant);
              }
            }
          } else {
            let selectedVariant = foundProduct.variants[0];
            let selectedSubVariant = selectedVariant.subvariants[0];
            setVariant(selectedVariant);
            setSubVariant(selectedSubVariant);
          }
        } else {
          console.log("not found Variant Params");
          let selectedVariant = foundProduct.variants[0];
          let selectedSubVariant = selectedVariant.subvariants[0];
          setVariant(selectedVariant);
          setSubVariant(selectedSubVariant);
        }
      } else {
        // Handle case when product is not found
        console.log(`Product with ID ${productId} not found.`);
      }
    }
  }, [productId, products, fetchProducts, variantParams, subVariantParams]);

  console.log(variant, subVariant);
  // useEffect(() => {
  //   // Change the Query Params according to changes in values of variant and subVariant
  //   if (variant && subVariant) {
  //     updateQueryParam("variant", variant.value);
  //     updateQueryParam("subVariant", subVariant.value);
  //   }
  // }, [variant, subVariant]);

  // const updateQueryParam = (paramName: string, paramValue: string) => {
  //   queryParams.set(paramName, paramValue);
  //   const newSearch = "?" + queryParams.toString();
  //   history.push({ search: newSearch });
  // };

  return (
    <div>
      {product && variant && subVariant ? (
        <Product
          product={product}
          variant={variant}
          subVariant={subVariant}
          setVariant={setVariant}
          setSubVariant={setSubVariant}
        />
      ) : (
        <p>Loading product details...</p>
      )}
    </div>
  );
};

export default ProductScreen;
