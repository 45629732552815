import React, { useState } from "react";
import { CartProductType, AddressItemType, CustomerType } from "../types";
import addData from "../firebase/addData";

const useCreateOrder = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const validateOrderData = (
    cart: CartProductType[],
    customer: {
      email: string;
      name: string;
      phoneNumber: string;
      uid: string;
    },
    currentAddress: AddressItemType,
    totalDiscount: string,
    shippingCharges: string,
    totalSP: string,
    netPaymentAmount: string
  ) => {};

  const createOrder = async (
    cart: CartProductType[],
    customer: {
      email: string;
      name: string;
      phoneNumber: string;
      uid: string;
    },
    currentAddress: AddressItemType,
    totalDiscount: string,
    shippingCharges: string,
    totalSP: string,
    netPaymentAmount: string,
    afterOrderRedirect: Function
  ) => {
    setLoading(true);
    setError(null);
    console.log("in CreateOrder");
    try {
      if (!cart || cart.length === 0) {
        throw new Error("Cart is empty. Please add items to the cart.");
      }

      if (!customer) {
        throw new Error(
          "Customer information is missing. Please provide customer details."
        );
      }

      if (!currentAddress) {
        throw new Error(
          "Current address is missing. Please provide the delivery address."
        );
      }
      if (!currentAddress.name) {
        throw new Error(
          "Address name is missing. Please provide the name associated with the address."
        );
      }

      if (!currentAddress.phoneNumber) {
        throw new Error(
          "Phone number is missing. Please provide the phone number for the address."
        );
      }

      if (!currentAddress.addressLine1) {
        throw new Error(
          "Address Line 1 is missing. Please provide the first line of the address."
        );
      }

      if (!currentAddress.city) {
        throw new Error("City is missing. Please provide the city.");
      }

      if (!currentAddress.postalCode) {
        throw new Error(
          "Postal code is missing. Please provide the postal code."
        );
      }

      if (!currentAddress.state) {
        throw new Error("State is missing. Please provide the state.");
      }

      // if (!totalDiscount) {
      //   throw new Error(
      //     "Total discount amount is missing. Please provide the discount amount."
      //   );
      // }

      if (!shippingCharges) {
        throw new Error(
          "Shipping charges are missing. Please provide the shipping cost."
        );
      }

      if (!totalSP) {
        throw new Error(
          "Total SP is missing. Please provide the total selling price."
        );
      }

      if (!netPaymentAmount) {
        throw new Error(
          "Net payment amount is missing. Please provide the total payment amount."
        );
      }

      const orderData = {
        user: customer,
        cart,
        currentAddress,
        currentStatus: "CREATED",
        netPaymentAmount,
        totalMRP: "calculateTotalMRP(cart).toString()", // Assuming you have a function to calculate total MRP
        totalSP,
        totalDiscount,
        createdAt: new Date(),
        shippingCharges,
        creationMethod: "ADMIN",
        transactionId: "",
        paymentType: "",
        statusHistory: [],
      };

      const { result, error } = await addData("orders", orderData);
      if (result) {
        window.location.href = createWhatsAppLink(cart, result.id);
      }

      afterOrderRedirect();
    } catch (error: any) {
      if (error.message && typeof error.message) {
        setError(error.message);
      } else setError("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return { loading, createOrder, error };
};

function createWhatsAppLink(cartItems: CartProductType[], orderId: string) {
  const phoneNumber = "+919467850090"; // Include country code

  let message = `🎉 *Hello! Thank you for your order.* 🎉\n\nHere are your *Order Details*:\n\n📄 *Order ID*: ${orderId}\n\n`;

  // Adding each cart item to the message with detailed information including variant, subVariant, and serial numbers
  cartItems.forEach((item, index) => {
    const serialNumber = index + 1; // Generate serial number starting from 1
    message += `🔹 *${serialNumber}. Item*: ${item.name}\n   └ 🎨 *Variant*: ${
      item.variant
    }\n   └ 📏 *Sub-Variant*: ${item.subVariant}\n   └ 📦 *Quantity*: ${
      item.quantity
    }\n   └ 💰 *Price per item*: ₹${item.SP}\n   └ 🧾 *Subtotal*: ₹${(
      Number(item.SP) * item.quantity
    ).toFixed(2)}\n\n`;
  });

  // Calculating the total price
  const totalPrice = cartItems
    .reduce((total, item) => total + Number(item.SP) * item.quantity, 0)
    .toFixed(2);

  // Adding total price to the message with an emoji for visual appeal
  message += `💳 *Total Price*: ₹${totalPrice}\n\n`;

  // Optional: Add additional instructions or information with a friendly emoji
  message += `📞 We will contact you shortly to confirm your order and provide payment and delivery details.\n\n🙏 *Thank you for choosing us!*`;
  const encodedMessage = encodeURIComponent(message);
  return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
}

export default useCreateOrder;
