import React from "react";
import NavbarHeading from "../Reusable/Heading/NavbarHeading";

function ShopDetail() {
  return (
    <>
      <NavbarHeading
        NavbarMainHeading="STORE"
        NavbarSubHeading="CARTZIO"
        NavbarItem="MENS BROWN JACKET"
      />
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-6 items-center">
            <div className="col-span-1">
              <div className="flex gap-2 flex-col">
                <div className="w-full p-[15px] border border-qgray-border cursor-pointer">
                  <img
                    src="https://shreethemes.in/cartzio/layouts/assets/images/shop/mens-jecket-3.jpg"
                    alt=""
                    className="w-full h-full object-contain  "
                  />
                </div>
                <div className="w-full  p-[15px] border border-qgray-border cursor-pointer">
                  <img
                    src="https://shreethemes.in/cartzio/layouts/assets/images/shop/mens-jecket-3.jpg"
                    alt=""
                    className="w-full h-full object-contain  "
                  />
                </div>
                <div className="w-full  p-[15px] border border-qgray-border cursor-pointer">
                  <img
                    src="https://shreethemes.in/cartzio/layouts/assets/images/shop/mens-jecket-3.jpg"
                    alt=""
                    className="w-full h-full object-contain opacity-50 "
                  />
                </div>
                <div className="w-full  p-[15px] border border-qgray-border cursor-pointer">
                  <img
                    src="https://shreethemes.in/cartzio/layouts/assets/images/shop/mens-jecket-3.jpg"
                    alt=""
                    className="w-full h-full object-contain opacity-50 "
                  />
                </div>
                <div className="w-full  p-[15px] border border-qgray-border cursor-pointer">
                  <img
                    src="https://shreethemes.in/cartzio/layouts/assets/images/shop/mens-jecket-3.jpg"
                    alt=""
                    className="w-full h-full object-contain opacity-50 "
                  />
                </div>
              </div>
            </div>
            <div className="col-span-5 w-full h-[600px] border border-qgray-border flex justify-center items-center overflow-hidden relative mb-3">
              <img
                src="https://shreethemes.in/cartzio/layouts/assets/images/shop/mens-jecket.jpg"
                alt=""
                className="object-contain"
              />
              <div className="w-full h-[80px] rounded-full bg-qyellow text-qblack flex justify-center items-center text-xl font-medium absolute left-[30px] top-[30px]"></div>
            </div>

            <div className="col-span-6">
              <h5 className="text-2xl font-semibold">Mens Brown Jecket</h5>
              <div className="mt-2">
                <span className="text-slate-400 font-semibold me-1">
                  $16USD <del className="text-red-600">₹21USD</del>
                </span>

                <ul className="list-none inline-block text-orange-400">
                  <li className="inline">
                    <i className="mdi mdi-star text-lg"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star text-lg"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star text-lg"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star text-lg"></i>
                  </li>
                  <li className="inline">
                    <i className="mdi mdi-star text-lg"></i>
                  </li>
                  <li className="inline text-slate-400 font-semibold">
                    4.8 (45)
                  </li>
                </ul>
              </div>

              <div className="mt-4">
                <h5 className="text-lg font-semibold">Overview :</h5>
                <p className="text-slate-400 mt-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero
                  exercitationem, unde molestiae sint quae inventore atque
                  minima natus fugiat nihil quisquam voluptates ea omnis. Modi
                  laborum soluta tempore unde accusantium.
                </p>

                <ul className="list-none text-slate-400 mt-4">
                  <li className="mb-1 flex">
                    <i className="mdi mdi-check-circle-outline text-orange-500 text-xl me-2"></i>{" "}
                    Digital Marketing Solutions for Tomorrow
                  </li>
                  <li className="mb-1 flex">
                    <i className="mdi mdi-check-circle-outline text-orange-500 text-xl me-2"></i>{" "}
                    Our Talented &amp; Experienced Marketing Agency
                  </li>
                  <li className="mb-1 flex">
                    <i className="mdi mdi-check-circle-outline text-orange-500 text-xl me-2"></i>{" "}
                    Create your own skin to match your brand
                  </li>
                </ul>
              </div>

              <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-4">
                <div className="flex items-center">
                  <h5 className="text-lg font-semibold me-2">Size:</h5>
                  <div className="space-x-1">
                    <a
                      href=""
                      className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white"
                    >
                      S
                    </a>
                    <a
                      href=""
                      className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white"
                    >
                      M
                    </a>
                    <a
                      href=""
                      className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white"
                    >
                      L
                    </a>
                    <a
                      href=""
                      className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white"
                    >
                      XL
                    </a>
                  </div>
                </div>

                <div className="flex items-center">
                  <h5 className="text-lg font-semibold me-2">Quantity:</h5>
                  <div className="qty-icons ms-3 space-x-0.5">
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                      className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white minus"
                    >
                      -
                    </button>
                    <input
                      min="0"
                      name="quantity"
                      value="0"
                      type="number"
                      className="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white pointer-events-none w-16 ps-4 quantity"
                    />
                    <button
                      onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                      className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white plus"
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="flex items-center">
                  <h5 className="text-lg font-semibold me-2">Colors:</h5>
                  <div className="space-x-2">
                    <a
                      href=""
                      className="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-red-600 inline-flex align-middle"
                      title="Red"
                    ></a>
                    <a
                      href=""
                      className="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-primary inline-flex align-middle"
                      title="Orange"
                    ></a>
                    <a
                      href=""
                      className="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-slate-900 inline-flex align-middle"
                      title="Black"
                    ></a>
                    <a
                      href=""
                      className="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-gray-300 inline-flex align-middle"
                      title="Gray"
                    ></a>
                  </div>
                </div>
              </div>

              <div className="mt-4 space-x-1">
                <a
                  href=""
                  className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2"
                >
                  Shop Now
                </a>
                <a
                  href=""
                  className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white mt-2"
                >
                  Add to Cart
                </a>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
            <div className="lg:col-span-3 md:col-span-5">
              <div className="sticky top-20">
                <ul
                  className="flex-column p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
                  id="myTab"
                  data-tabs-toggle="#myTabContent"
                  role="tablist"
                >
                  <li role="presentation">
                    <button
                      className="px-4 py-2 text-start text-base font-semibold rounded-md w-full duration-500 text-white bg-orange-500"
                      id="description-tab"
                      data-tabs-target="#description"
                      type="button"
                      role="tab"
                      aria-controls="description"
                      aria-selected="true"
                    >
                      Description
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      className="px-4 py-2 text-start text-base font-semibold rounded-md w-full mt-3 duration-500 hover:text-orange-500 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"
                      id="addinfo-tab"
                      data-tabs-target="#addinfo"
                      type="button"
                      role="tab"
                      aria-controls="addinfo"
                      aria-selected="false"
                    >
                      Additional Information
                    </button>
                  </li>
                  <li role="presentation">
                    <button
                      className="px-4 py-2 text-start text-base font-semibold rounded-md w-full mt-3 duration-500 hover:text-orange-500 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"
                      id="review-tab"
                      data-tabs-target="#review"
                      type="button"
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      Review
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="lg:col-span-9 md:col-span-7">
              <div
                id="myTabContent"
                className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md"
              >
                <div
                  className=""
                  id="description"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <p className="text-slate-400">
                    Due to its widespread use as filler text for layouts,
                    non-readability is of great importance: human perception is
                    tuned to recognize certain patterns and repetitions in
                    texts. If the distribution of letters and 'words' is random,
                    the reader will not be distracted from making a neutral
                    judgement on the visual impact and readability of the
                    typefaces (typography), or the distribution of text on the
                    page (layout or type area). For this reason, dummy text
                    usually consists of a more or less random series of words or
                    syllables.
                  </p>
                </div>

                <div
                  className="hidden"
                  id="addinfo"
                  role="tabpanel"
                  aria-labelledby="addinfo-tab"
                >
                  <table className="w-full text-start">
                    <tbody>
                      <tr className="bg-white dark:bg-slate-900">
                        <td className="font-semibold pb-4 w-full">Color</td>
                        <td className="text-slate-400 pb-4">
                          Red, White, Black, Orange
                        </td>
                      </tr>

                      <tr className="bg-white dark:bg-slate-900 border-t border-gray-100 dark:border-gray-700">
                        <td className="font-semibold py-4">Material</td>
                        <td className="text-slate-400 py-4">Cotton</td>
                      </tr>

                      <tr className="bg-white dark:bg-slate-900 border-t border-gray-100 dark:border-gray-700">
                        <td className="font-semibold pt-4">Size</td>
                        <td className="text-slate-400 pt-4">
                          S, M, L, XL, XXL
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ShopDetail;
