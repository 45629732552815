import React, { useState } from "react";
import CartButton from "../Button/CartButton";
import CartOne from "../CartImages/CartOne";
import NavbarHeading from "../Heading/NavbarHeading";
import { ProductType } from "../../types";
import { Link } from "react-router-dom";

interface Props {
  filteredProducts: ProductType[];
}

const ShopList: React.FC<Props> = ({ filteredProducts }) => {
  return (
    <>
      <section className="relative md:pb-24 pb-16 pt-4">
        <div className="container relative">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
            {filteredProducts.map((product) => (
              <ProductCard product={product} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const ProductCard: React.FC<{ product: ProductType }> = ({ product }) => {
  const firstVariant = product.variants[0];
  const [selectedVariant, setSelectedVariant] = useState(firstVariant);
  let discountPercentage = "0";
  let SP = 0;
  let MRP = 0;
  if (selectedVariant && selectedVariant.subvariants.length > 0) {
    SP = parseFloat(selectedVariant.subvariants[0].SP);
    MRP = parseFloat(selectedVariant.subvariants[0].MRP);

    if (!isNaN(SP) && !isNaN(MRP)) {
      // Calculate discount percentage only if SP and MRP are valid numbers
      discountPercentage = ((1 - SP / MRP) * 100).toFixed(0);
    }
  }
  const productLink = `/product/${product.id}`;
  return (
    <div
      key={product.id}
      className="group relative duration-500 w-full mx-auto"
    >
      <div className="md:flex items-center">
        <CartOne
          productImages={product.featureImage}
          productDiscount={discountPercentage}
          productImage=""
        />
        <div className="md:ms-6 md:mt-0 mt-4">
          <Link
            to={productLink}
            className="hover:text-orange-500 text-lg font-medium"
          >
            {product.productName}
          </Link>
          <p className="text-slate-400 md:block hidden mt-2">
            Viverra a consectetur Go sporty this vintage navy and white striped.
          </p>
          <p className="mt-2 font-bold">
            ₹{SP}{" "}
            <span className="mt-2 font-light text-sm line-through">₹{MRP}</span>
          </p>

          <div className="flex gap-2 mt-2">
            {product.variants.map((variant) => {
              const isSelected = variant.value === selectedVariant.value;
              return (
                <div
                  key={variant.value}
                  style={{
                    backgroundColor: variant.value,
                    border: isSelected ? "ring-gray-700" : "",
                  }}
                  onClick={() => setSelectedVariant(variant)}
                  className={`size-6 rounded-full ring-4 ring-gray-200 dark:ring-slate-800  inline-flex align-middle cursor-pointer  ${
                    isSelected ? "ring-gray-700 transition-shadow" : ""
                  }`}
                ></div>
              );
            })}
          </div>
          <div className="mt-4">
            <CartButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopList;
