import React from 'react'

function CartButton() {
    return (
        <div>
            <a href="shop-cart.html" className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white rounded-md">Add to Cart</a>
        </div>
    )
}

export default CartButton