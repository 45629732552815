import logo from "../Navbar/logo.svg";

const Footer = () => {
  return (
    <footer class="footer bg-slate-950 relative text-gray-200 dark:text-gray-200">
      <div class="container relative">
        <div class="grid grid-cols-12">
          <div class="col-span-12">
            <div class="py-[60px] px-0">
              <div class="grid md:grid-cols-12 grid-cols-1 gap-6">
                <div class="lg:col-span-3 md:col-span-12">
                  <a href="#" class="text-[22px] focus:outline-none">
                    <img src={logo} alt="" />
                  </a>
                  <p class="mt-6 text-gray-300">
                    Upgrade your style with our curated sets. Choose confidence,
                    embrace your unique look.
                  </p>
                  <ul class="list-none mt-6">
                    <li class="inline">
                      <a
                        href="https://1.envato.market/cartzio"
                        target="_blank"
                        class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-shopping-cart h-4 w-4 align-middle"
                          title="Buy Now"
                        >
                          <circle cx="9" cy="21" r="1"></circle>
                          <circle cx="20" cy="21" r="1"></circle>
                          <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                        </svg>
                      </a>
                    </li>
                    <li class="inline">
                      <a
                        href="https://dribbble.com/shreethemes"
                        target="_blank"
                        class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-dribbble h-4 w-4 align-middle"
                          title="dribbble"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <path d="M8.56 2.75c4.37 6.03 6.02 9.42 8.03 17.72m2.54-15.38c-3.72 4.35-8.94 5.66-16.88 5.85m19.5 1.9c-3.5-.93-6.63-.82-8.94 0-2.58.92-5.01 2.86-7.44 6.32"></path>
                        </svg>
                      </a>
                    </li>
                    <li class="inline">
                      <a
                        href="http://linkedin.com/company/shreethemes"
                        target="_blank"
                        class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-linkedin h-4 w-4 align-middle"
                          title="Linkedin"
                        >
                          <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                          <rect x="2" y="9" width="4" height="12"></rect>
                          <circle cx="4" cy="4" r="2"></circle>
                        </svg>
                      </a>
                    </li>
                    <li class="inline">
                      <a
                        href="https://www.facebook.com/shreethemes"
                        target="_blank"
                        class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-facebook h-4 w-4 align-middle"
                          title="facebook"
                        >
                          <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                        </svg>
                      </a>
                    </li>
                    <li class="inline">
                      <a
                        href="https://www.instagram.com/shreethemes/"
                        target="_blank"
                        class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-instagram h-4 w-4 align-middle"
                          title="instagram"
                        >
                          <rect
                            x="2"
                            y="2"
                            width="20"
                            height="20"
                            rx="5"
                            ry="5"
                          ></rect>
                          <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                          <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                        </svg>
                      </a>
                    </li>
                    <li class="inline">
                      <a
                        href="https://twitter.com/shreethemes"
                        target="_blank"
                        class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-twitter h-4 w-4 align-middle"
                          title="twitter"
                        >
                          <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                        </svg>
                      </a>
                    </li>
                    <li class="inline">
                      <a
                        href="mailto:support@shreethemes.in"
                        class="size-8 inline-flex items-center justify-center tracking-wide align-middle text-base border border-gray-800 dark:border-slate-800 rounded-md hover:text-orange-500 dark:hover:text-orange-500 text-slate-300"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-mail h-4 w-4 align-middle"
                          title="email"
                        >
                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                          <polyline points="22,6 12,13 2,6"></polyline>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="lg:col-span-6 md:col-span-12">
                  <h5 class="tracking-[1px] text-gray-100 font-semibold">
                    Shopping &amp; Clothes
                  </h5>

                  <div class="grid md:grid-cols-12 grid-cols-1">
                    <div class="md:col-span-4">
                      <ul class="list-none footer-list mt-6">
                        <li>
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Men
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Jackets &amp;
                            Coats
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Jeans
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Loungewear
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Polo shirts
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Shirts
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="md:col-span-4">
                      <ul class="list-none footer-list mt-6">
                        <li>
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Shorts
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Suits Swimwear
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> T-shirts
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Tracksuits
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Trousers
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Shirts
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="md:col-span-4">
                      <ul class="list-none footer-list mt-6">
                        <li>
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> My account
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Order History
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Wish List
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Newsletter
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Affiliate
                          </a>
                        </li>
                        <li class="mt-[10px]">
                          <a
                            href=""
                            class="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"
                          >
                            <i class="mdi mdi-chevron-right"></i> Returns
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="lg:col-span-3 md:col-span-4">
                  <h5 class="tracking-[1px] text-gray-100 font-semibold">
                    Newsletter
                  </h5>
                  <p class="mt-6">
                    Sign up and receive the latest tips via email.
                  </p>
                  <form>
                    <div class="grid grid-cols-1">
                      <div class="my-3">
                        <label class="form-label">
                          Write your email <span class="text-red-600">*</span>
                        </label>
                        <div class="form-icon relative mt-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-mail size-4 absolute top-3 start-4"
                          >
                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                            <polyline points="22,6 12,13 2,6"></polyline>
                          </svg>
                          <input
                            type="email"
                            class="ps-12 rounded w-full py-2 px-3 h-10 bg-gray-800 border-0 text-gray-100 focus:shadow-none focus:ring-0 placeholder:text-gray-200 outline-none"
                            placeholder="Email"
                            name="email"
                            required=""
                          />
                        </div>
                      </div>

                      <button
                        type="submit"
                        id="submitsubscribe"
                        name="send"
                        class="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-orange-500 text-white rounded-md"
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1">
          <div class="py-[30px] px-0 border-t border-slate-800">
            <div class="grid lg:grid-cols-4 md:grid-cols-2">
              <div class="flex items-center lg:justify-center">
                <i class="mdi mdi-truck-check-outline align-middle text-lg mb-0 me-2"></i>
                <h6 class="mb-0 font-medium">Free delivery</h6>
              </div>
              <div class="flex items-center lg:justify-center">
                <i class="mdi mdi-archive align-middle text-lg mb-0 me-2"></i>
                <h6 class="mb-0 font-medium">Non-contact shipping</h6>
              </div>
              <div class="flex items-center lg:justify-center">
                <i class="mdi mdi-cash-multiple align-middle text-lg mb-0 me-2"></i>
                <h6 class="mb-0 font-medium">Money-back quarantee</h6>
              </div>
              <div class="flex items-center lg:justify-center">
                <i class="mdi mdi-shield-check align-middle text-lg mb-0 me-2"></i>
                <h6 class="mb-0 font-medium">Secure payments</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-[30px] px-0 border-t border-slate-800">
        <div class="container relative text-center">
          <div class="grid md:grid-cols-2 items-center">
            <div class="md:text-start text-center">
              <p class="mb-0">
                © <script>document.write(new Date().getFullYear())</script>2024
                Cartzio. Design with <i class="mdi mdi-heart text-red-600"></i>
                by
                <a
                  href="https://shreethemes.in/"
                  target="_blank"
                  class="text-reset"
                >
                  Shreethemes
                </a>
                .
              </p>
            </div>

            <ul class="list-none md:text-end text-center mt-6 md:mt-0">
              <li class="inline">
                <a href="">
                  <img
                    src="assets/images/payments/american-express.jpg"
                    class="max-h-6 rounded inline"
                    title="American Express"
                    alt=""
                  />
                </a>
              </li>
              <li class="inline">
                <a href="">
                  <img
                    src="assets/images/payments/discover.jpg"
                    class="max-h-6 rounded inline"
                    title="Discover"
                    alt=""
                  />
                </a>
              </li>
              <li class="inline">
                <a href="">
                  <img
                    src="assets/images/payments/mastercard.jpg"
                    class="max-h-6 rounded inline"
                    title="Master Card"
                    alt=""
                  />
                </a>
              </li>
              <li class="inline">
                <a href="">
                  <img
                    src="assets/images/payments/paypal.jpg"
                    class="max-h-6 rounded inline"
                    title="Paypal"
                    alt=""
                  />
                </a>
              </li>
              <li class="inline">
                <a href="">
                  <img
                    src="assets/images/payments/visa.jpg"
                    class="max-h-6 rounded inline"
                    title="Visa"
                    alt=""
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
