import { useState, useEffect } from "react";

import Popover from "./Mobile/PopoverMobile";
import PopoverMobile from "./Mobile/PopoverMobile";

const CartegoryDropdown = (props) => {
  let { categories, setIsOpen } = props;
  categories = categories.map((cat) => {
    let options = cat.subCategories.map((subCat) => {
      return {
        name: subCat.name,
        imageUrl: subCat.imgUrl,
        slug: `/subCategory/${subCat.name}`,
      };
    });
    return {
      name: cat.name,
      options: options,
    };
  });

  return (
    <div className="flex-col gap-3">
      {categories.map((cat) => {
        return (
          <PopoverMobile
            key={cat.name}
            name={cat.name}
            options={cat.options}
            setIsOpen={setIsOpen}
          />
        );
      })}
      {/* <Popover category={category}/>
      <Popover category={category}/> */}
    </div>
  );
};
export default CartegoryDropdown;
