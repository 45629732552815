import BrandsDropdown from "../BrandsDropdown";
import CategoriesDropdown from "../CategoriesDropdown";
import { useMetaDataContext } from "../../../Context/MetaDataContext";

const NavBarDownMobile = (props) => {
  const { setIsOpen } = props;
  const { metaData } = useMetaDataContext();
  console.warn(metaData);
  let brands = [];
  let categories = [];
  const brandsObj = metaData.find((i) => i.id === "brands");
  if (brandsObj) {
    brands = brandsObj.brands;
  }
  const categoriesObj = metaData.find((i) => i.id === "categories");
  if (categoriesObj) {
    categories = categoriesObj.categories;
  }
  console.warn(categories);
  return (
    <>
      <div className=" pb-3 flex-col gap-3 z-16">
        <BrandsDropdown brands={brands} />
        <CategoriesDropdown categories={categories} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};

export default NavBarDownMobile;
