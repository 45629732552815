import { EyeDropperIcon, StarIcon } from "@heroicons/react/24/solid";
import { useProductsContext } from "../../Context/ProductsContext";
import CartOne from "../CartImages/CartOne";
import NavbarHeading from "../Heading/NavbarHeading";
import ProductHeading from "../Heading/ProductHeading";
import { ProductType } from "../../types";
import { useState } from "react";
import { BookmarkIcon, EyeIcon, HeartIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const NewProduct = [
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },

  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
];

function ShopGrid() {
  const { products, fetchProducts } = useProductsContext();

  return (
    <>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 pt-6 gap-6">
        {products?.map((product) => {
          return <ProductCard product={product} />;
        })}
      </div>
    </>
  );
}

const ProductCard: React.FC<{ product: ProductType }> = ({ product }) => {
  const firstVariant = product.variants[0];
  const [selectedVariant, setSelectedVariant] = useState(firstVariant);
  let discountPercentage = "0";
  let SP = 0;
  let MRP = 0;
  if (selectedVariant && selectedVariant.subvariants.length > 0) {
    SP = parseFloat(selectedVariant.subvariants[0].SP);
    MRP = parseFloat(selectedVariant.subvariants[0].MRP);

    if (!isNaN(SP) && !isNaN(MRP)) {
      // Calculate discount percentage only if SP and MRP are valid numbers
      discountPercentage = ((1 - SP / MRP) * 100).toFixed(0);
    }
  }
  const productLink = `/product/${product.id}`;
  return (
    <div className="group">
      <div className="relative overflow-hidden shadow-lg dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500">
        <div style={{ aspectRatio: "3/4" }}>
          <img
            src={product.featureImage}
            className="group-hover:scale-110 duration-500 object-cover"
            style={{ width: "100%", height: "100%" }}
          />
        </div>

        <div className="absolute -bottom-20 group-hover:bottom-3 start-3 end-3 duration-500">
          <a
            href="shop-cart.html"
            className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center bg-slate-900 text-white w-full rounded-md"
          >
            Add to Cart
          </a>
        </div>

        <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
          <li>
            <Link
              className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
              to="/"
            >
              <HeartIcon className="h-5 w-5" />
            </Link>
          </li>
          <li className="mt-1">
            <Link
              to="/"
              className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
            >
              <EyeIcon className="h-5 w-5" />
            </Link>
          </li>
          <li className="mt-1">
            <Link
              to="/"
              className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
            >
              <BookmarkIcon className="h-5 w-5" />
            </Link>
          </li>
        </ul>

        <ul className="list-none absolute top-[10px] start-4">
          <li>
            {+discountPercentage > 0 && (
              <a className="bg-primary text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">
                {discountPercentage}%
              </a>
            )}
          </li>
          <li>
            <a className="bg-red-600 text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5">
              New
            </a>
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <a
          href="product-detail-one.html"
          className="hover:text-orange-500 text-lg font-medium"
        >
          {product.productName}
        </a>
        <div className="flex gap-2 justify-between items-center mt-1">
          <p>
            <p className="font-bold inline text-lg">₹{SP}</p>
            <del className="text-slate-400 pl-1">₹{MRP}</del>
          </p>
          <ul className="font-medium text-amber-400 list-none flex">
            <StarIcon className="h-3 w-3" />
            <StarIcon className="h-3 w-3" />
            <StarIcon className="h-3 w-3" />
            <StarIcon className="h-3 w-3" />
            <StarIcon className="h-3 w-3" />
          </ul>
        </div>
        <div className="flex gap-2 mt-2">
          {product.variants.map((variant) => {
            console.log(variant.value);
            const isSelected = variant.value === selectedVariant.value;
            console.log(isSelected);
            return (
              <div
                key={variant.value}
                style={{
                  backgroundColor: variant.value,
                  border: isSelected ? "ring-gray-700" : "",
                }}
                onClick={() => setSelectedVariant(variant)}
                className={`size-6 rounded-full ring-4 ring-gray-200 dark:ring-slate-800  inline-flex align-middle cursor-pointer  ${
                  isSelected ? "ring-gray-700 transition-shadow" : ""
                }`}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShopGrid;
