import React from "react";
import CartOne from "../Reusable/CartImages/CartOne";
import ProductHeading from "../Reusable/Heading/ProductHeading";
import NavbarHeading from "../Reusable/Heading/NavbarHeading";
import CartButton from "../Reusable/Button/CartButton";

const ProductList = [
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },

  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
  {
    productPhoto:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productDiscount: "-40% Off",
    productName: "Black Print T-Shirt",
    productNewPrice: "₹16.00",
    productOldPrice: "₹21.00",
  },
];
function ShopList() {
  return (
    <>
      <NavbarHeading
        NavbarMainHeading="Fashion"
        NavbarSubHeading="CARTZIO"
        NavbarItem="SHOP LIST"
      />
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
            {ProductList.map((products) => (
              <div className="group relative duration-500 w-full mx-auto">
                <div className="md:flex items-center">
                  <CartOne
                    productImages={products.productPhoto}
                    productDiscount={products.productDiscount}
                  />
                  <div className="md:ms-6 md:mt-0 mt-4">
                    <a
                      href="product-detail-one.html"
                      className="hover:text-orange-500 text-lg font-medium"
                    >
                      Black Print T-Shirt
                    </a>
                    <p className="text-slate-400 md:block hidden mt-2">
                      Viverra a consectetur Go sporty this vintage navy and
                      white striped.
                    </p>
                    <p className="mt-2">₹16.00</p>
                    <div className="flex gap-2 mt-2">
                      <div className="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-red-600 inline-flex align-middle"></div>
                      <div className="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-indigo-600 inline-flex align-middle"></div>
                      <div className="size-6 rounded-full ring-2 ring-gray-200 dark:ring-slate-800 bg-emerald-600 inline-flex align-middle"></div>
                    </div>

                    <div className="mt-4">
                      <CartButton />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default ShopList;
