import React from "react";
import NavbarHeading from "../Reusable/Heading/NavbarHeading";
import MobileApp from "../Reusable/MobileApp/MobileApp";
import { useCartContext } from "../Context/CartContext";
import { CartProductType } from "../types";
import { Link } from "react-router-dom";

function Cart() {
  const { cart, dispatch } = useCartContext();

  let totalSP = 0;
  let totalMRP = 0;
  cart.forEach((item) => {
    let itemTotalSP = item.quantity * +item.SP;
    let itemTotalMRP = item.quantity * +item.MRP;
    totalSP += itemTotalSP;
    totalMRP += itemTotalMRP;
  });
  const handleAddToCart = (item: CartProductType) => {
    // Dispatch the ADD_TO_CART action
    dispatch({
      type: "ADD_TO_CART",
      payload: {
        SP: item.SP,
        MRP: +item.MRP,
        imgUrl: item.imgUrl,
        name: item.name,
        quantity: 1,
        subVariant: item.subVariant,
        variant: item.variant,
        id: item.id,
      },
    });
  };
  const handleRemoveFromCart = (item: CartProductType) => {
    // Dispatch the ADD_TO_CART action
    dispatch({
      type: "DECREMENT_QUANTITY",
      payload: {
        SP: item.SP,
        MRP: +item.MRP,
        imgUrl: item.imgUrl,
        name: item.name,
        quantity: 1,
        subVariant: item.subVariant,
        variant: item.variant,
        id: item.id,
      },
    });
  };

  return (
    <>
      <NavbarHeading
        NavbarMainHeading="Cart"
        NavbarSubHeading="CARTZIO"
        NavbarItem="SHOP CART"
      />
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid lg:grid-cols-1">
            <div className="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
              <table className="w-full text-start">
                <thead className="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                  <tr>
                    <th scope="col" className="p-4 w-4"></th>
                    <th scope="col" className="text-start p-4 min-w-[220px]">
                      Product
                    </th>
                    <th scope="col" className="p-4 w-24 min-w-[100px]">
                      Price
                    </th>
                    <th scope="col" className="p-4 w-56 min-w-[220px]">
                      Qty
                    </th>
                    <th scope="col" className="p-4 w-24 min-w-[100px]">
                      Total(₹)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((item, index) => {
                    const total = item.quantity * +item.SP;
                    return (
                      <tr className="bg-white dark:bg-slate-900">
                        <td className="p-4">
                          <a href="">
                            <i className="mdi mdi-window-close text-red-600"></i>
                            {index + 1}
                          </a>
                        </td>
                        <td className="p-4">
                          <span className="flex items-center">
                            <img
                              src={item.imgUrl}
                              className="rounded shadow dark:shadow-gray-800 w-12"
                              alt=""
                            />
                            <span className="ms-3">
                              <span className="block font-semibold">
                                {item.name}
                              </span>
                            </span>
                          </span>
                        </td>
                        <td className="p-4 text-center">{item.SP}</td>
                        <td className="p-4 text-center">
                          <div className="qty-icons">
                            <button
                              className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white minus"
                              onClick={() => handleRemoveFromCart(item)}
                            >
                              -
                            </button>
                            <input
                              min="0"
                              name="quantity"
                              value={item.quantity}
                              type="number"
                              className="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white pointer-events-none w-16 ps-4 quantity"
                            />
                            <button
                              className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white plus"
                              onClick={() => handleAddToCart(item)}
                            >
                              +
                            </button>
                          </div>
                        </td>
                        <td className="p-4  text-end">{total}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
              <div className="lg:col-span-9 md:order-1 order-3">
                <div className="space-x-1">
                  <Link
                    to={"/checkout"}
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2"
                  >
                    Proceed to Checkout
                  </Link>
                  {/* <a
                    href=""
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white mt-2"
                  >
                    Proceed to Checkout
                  </a> */}
                </div>
              </div>

              <div className="lg:col-span-3 md:order-2 order-1">
                <ul className="list-none shadow dark:shadow-gray-800 rounded-md">
                  <li className="flex justify-between p-4">
                    <span className="font-semibold text-lg">Subtotal :</span>
                    <span className="text-slate-400">₹ {totalSP}</span>
                  </li>
                  <li className="flex justify-between p-4 border-t border-gray-100 dark:border-gray-800">
                    <span className="font-semibold text-lg">Taxes :</span>
                    <span className="text-slate-400">₹ 150</span>
                  </li>
                  <li className="flex justify-between font-semibold p-4 border-t border-gray-200 dark:border-gray-600">
                    <span className="font-semibold text-lg">Total :</span>
                    <span className="font-semibold">₹ {totalMRP}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <MobileApp />
      </section>
    </>
  );
}

export default Cart;
