import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

export default function PopoverMobile(props) {
  const { name, options, color, setIsOpen } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);

  return (
    <div className="relative group w-full mt-1">
      <div
        onClick={() => setPopoverOpen(!popoverOpen)}
        className={`
          ${popoverOpen ? "text-opacity-100" : "text-opacity-90"} 
          group w-full justify-between cursor-pointer inline-flex items-center rounded-md px-2 py-1 text-base font-medium text-white 
          ${color ? "bg-black" : "bg-secondary"}`}
      >
        <span>{name}</span>
        <span
          className={`ml-2 h-5 w-5 text-secondary transition-transform 
          ${popoverOpen ? "rotate-180" : ""}`}
          aria-hidden="true"
        >
          <ChevronUpIcon className="text-white" />
          {/* {popoverOpen ? <ChevronUpIcon /> : <ChevronDownIcon />} */}
        </span>
      </div>
      {popoverOpen && (
        <div className="transition">
          <div
            static
            className={`
             left-0  z-50
            transform opacity-100 translate-y-0
            transition-transform ease-in-out duration-200`}
          >
            <div
              className="overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 pt-3"
              onMouseEnter={() => setPopoverOpen(true)}
              onMouseLeave={() => setPopoverOpen(false)}
            >
              <div className="grid gap-8 bg-white p-7 py-3 pb-5 ">
                {options.map((item) => (
                  <Link
                    key={item.name}
                    to={item.slug ? item.slug : ""}
                    onClick={() => setIsOpen(false)}
                    className="-m-3 flex items-center rounded-lg p-1 bg-pink-100 transition duration-150 ease-in-out hover:bg-primary/20 hover:text-white focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 cursor-pointer"
                  >
                    <div className="flex h-10 w-10 shrink-0 items-center justify-center sm:h-12 sm:w-12 overflow-hidden rounded-sm">
                      <img src={item.imageUrl} />
                    </div>
                    <div className="ml-4">
                      <p className="text-sm text-inherit font-medium text-gray-900">
                        {item.name}
                      </p>
                    </div>
                  </Link>
                ))}
                <Link className="-m-3 flex items-center justify-center rounded-lg p-1 bg-pink-200 transition duration-150 ease-in-out hover:bg-primary/20 hover:text-white focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 cursor-pointer">
                  <div className="flex h-10 w-0 shrink-0 items-center justify-center sm:h-12 sm:w-12 overflow-hidden rounded-sm">
                    {/* <img src={""} /> */}
                  </div>
                  <div className="">
                    <p className="text-sm text-inherit font-medium text-gray-900">
                      {"View all "}
                      {name}
                    </p>
                    {/* <p className="text-sm text-gray-500">
                                            {item.description}
                                        </p> */}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// IconOne, IconTwo, and IconThree functions remain unchanged

// IconOne, IconTwo, and IconThree functions remain unchanged
