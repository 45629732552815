import React, { useEffect, useState } from "react";
import { useProductsContext } from "../Context/ProductsContext";
import { ProductType, VariantType, SubVariantType } from "../types";
import Product from "../Container/Product/Product";
import { useHistory, useLocation } from "react-router-dom";
import MyAccount from "../Container/MyAccount";

const MyAccountScreen: React.FC = () => {
  return <MyAccount />;
};

export default MyAccountScreen;
