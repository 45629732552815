import React from "react";
import NavbarHeading from "../Reusable/Heading/NavbarHeading";
import MobileApp from "../Reusable/MobileApp/MobileApp";
import { useCartContext } from "../Context/CartContext";

const productList = [
  {
    productImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productName: "T-shirt (M)",
    productPrice: "₹ 280",
    productTotal: "₹ 840",
  },
  {
    productImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productName: "T-shirt (M)",
    productPrice: "₹ 280",
    productTotal: "₹ 840",
  },
  {
    productImg:
      "https://shreethemes.in/cartzio/layouts/assets/images/shop/black-print-t-shirt.jpg",
    productName: "T-shirt (M)",
    productPrice: "₹ 280",
    productTotal: "₹ 840",
  },
];

function ShopCart() {
  const { cart, dispatch } = useCartContext();

  return (
    <>
      <NavbarHeading
        NavbarMainHeading="Shopcart"
        NavbarSubHeading="CARTZIO"
        NavbarItem="SHOP CART"
      />
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid lg:grid-cols-1">
            <div className="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
              <table className="w-full text-start">
                <thead className="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                  <tr>
                    <th scope="col" className="p-4 w-4"></th>
                    <th scope="col" className="text-start p-4 min-w-[220px]">
                      Product
                    </th>
                    <th scope="col" className="p-4 w-24 min-w-[100px]">
                      Price
                    </th>
                    <th scope="col" className="p-4 w-56 min-w-[220px]">
                      Qty
                    </th>
                    <th scope="col" className="p-4 w-24 min-w-[100px]">
                      Total(₹)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((productCart) => {
                    const total = productCart.quantity * +productCart.SP;
                    return (
                      <tr className="bg-white dark:bg-slate-900">
                        <td className="p-4">
                          <a href="">
                            <i className="mdi mdi-window-close text-red-600"></i>
                            *
                          </a>
                        </td>
                        <td className="p-4">
                          <span className="flex items-center">
                            <img
                              src={productCart.imgUrl}
                              className="rounded shadow dark:shadow-gray-800 w-12"
                              alt=""
                            />
                            <span className="ms-3">
                              <span className="block font-semibold">
                                {productCart.name}
                              </span>
                            </span>
                          </span>
                        </td>
                        <td className="p-4 text-center">{productCart.SP}</td>
                        <td className="p-4 text-center">
                          <div className="qty-icons">
                            <button className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white minus">
                              -
                            </button>
                            <input
                              min="0"
                              name="quantity"
                              value="3"
                              type="number"
                              className="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white pointer-events-none w-16 ps-4 quantity"
                            />
                            <button className="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white plus">
                              +
                            </button>
                          </div>
                        </td>
                        <td className="p-4  text-end">{total}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
              <div className="lg:col-span-9 md:order-1 order-3">
                <div className="space-x-1">
                  <a
                    href=""
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2"
                  >
                    Shop Now
                  </a>
                  <a
                    href=""
                    className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white mt-2"
                  >
                    Add to Cart
                  </a>
                </div>
              </div>

              <div className="lg:col-span-3 md:order-2 order-1">
                <ul className="list-none shadow dark:shadow-gray-800 rounded-md">
                  <li className="flex justify-between p-4">
                    <span className="font-semibold text-lg">Subtotal :</span>
                    <span className="text-slate-400">₹ 1500</span>
                  </li>
                  <li className="flex justify-between p-4 border-t border-gray-100 dark:border-gray-800">
                    <span className="font-semibold text-lg">Taxes :</span>
                    <span className="text-slate-400">₹ 150</span>
                  </li>
                  <li className="flex justify-between font-semibold p-4 border-t border-gray-200 dark:border-gray-600">
                    <span className="font-semibold text-lg">Total :</span>
                    <span className="font-semibold">₹ 1650</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <MobileApp />
      </section>
    </>
  );
}

export default ShopCart;
