import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import { CartProductType } from "../types";

interface CartState extends Array<CartProductType> {}

interface CartAction {
  type: string;
  payload: CartProductType;
}

interface CartContextType {
  cart: CartState;
  dispatch: React.Dispatch<CartAction>;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

const cartReducer = (state: CartState, action: CartAction): CartState => {
  switch (action.type) {
    case "ADD_TO_CART":
      var existingItemIndex = state.findIndex(
        (item) =>
          item.id === action.payload.id &&
          item.variant === action.payload.variant &&
          item.subVariant === action.payload.subVariant
      );

      if (existingItemIndex !== -1) {
        const newState = [...state];
        console.log("Q", newState[existingItemIndex].quantity);
        newState[existingItemIndex].quantity += action.payload.quantity;
        return newState;
      } else {
        return [...state, action.payload];
      }

    case "REMOVE_FROM_CART":
      return state.filter(
        (item) =>
          !(
            item.id === action.payload.id &&
            item.variant === action.payload.variant &&
            item.subVariant === action.payload.subVariant
          )
      );

    case "INCREMENT_QUANTITY":
      return state.map((item) =>
        item.id === action.payload.id &&
        item.variant === action.payload.variant &&
        item.subVariant === action.payload.subVariant
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );

    case "DECREMENT_QUANTITY":
      var existingItemIndex = state.findIndex(
        (item) =>
          item.id === action.payload.id &&
          item.variant === action.payload.variant &&
          item.subVariant === action.payload.subVariant
      );
      if (state[existingItemIndex].quantity > 1) {
        return state.map((item) =>
          item.id === action.payload.id &&
          item.variant === action.payload.variant &&
          item.subVariant === action.payload.subVariant
            ? {
                ...item,
                quantity: item.quantity > 1 ? item.quantity - 1 : item.quantity,
              }
            : item
        );
      } else {
        return state.filter(
          (item) =>
            !(
              item.id === action.payload.id &&
              item.variant === action.payload.variant &&
              item.subVariant === action.payload.subVariant
            )
        );
      }

    default:
      return state;
  }
};

interface CartProviderProps {
  children: ReactNode;
}

const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, [], () => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

const useCartContext = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCartContext must be used within a CartProvider");
  }
  return context;
};

export { CartProvider, useCartContext };
