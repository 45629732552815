import React from "react";
import NavbarHeading from "../Reusable/Heading/NavbarHeading";
import Checkout from "../Container/Checkout/Checkout";

export type AddressItemType = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  id: string;
  name: string;
  phoneNumber: string;
  postalCode: string;
  state: string;
};

function CheckoutScreen() {
  return (
    <>
      <h1 className="container bold text-2xl pt-4">Order almost complete</h1>
      <Checkout />
    </>
  );
}

export default CheckoutScreen;
