import { useMetaDataContext } from "../../../Context/MetaDataContext";
import PopoverWeb from "./PopoverWeb";

const NavBarDownWeb = () => {
  const { metaData } = useMetaDataContext();
  console.warn(metaData);
  let brands = [];
  let categories = [];
  const brandsObj = metaData.find((i) => i.id === "brands");
  if (brandsObj) {
    brands = brandsObj.brands;
  }
  const categoriesObj = metaData.find((i) => i.id === "categories");
  if (categoriesObj) {
    categories = categoriesObj.categories;
  }
  console.warn(categories);
  return (
    <>
      <div className=" pb-3 flex gap-3 z-16">
        <BrandsDropdown brands={brands} />
        <CategoriesDropdown categories={categories} />
      </div>
    </>
  );
};

const BrandsDropdown = (props) => {
  const { brands } = props;

  return (
    <div className="flex gap-3">
      <PopoverWeb color="black" name={"Shop by Brands"} options={brands} />
    </div>
  );
};

const CategoriesDropdown = (props) => {
  let { categories } = props;
  categories = categories.map((cat) => {
    let options = cat.subCategories.map((subCat) => {
      return {
        name: subCat.name,
        imageUrl: subCat.imgUrl,
        slug: `/subcategory/${subCat.name}`,
      };
    });
    return {
      name: cat.name,
      options: options,
    };
  });

  return (
    <div className="flex gap-3">
      {categories.map((cat) => {
        return (
          <PopoverWeb key={cat.name} name={cat.name} options={cat.options} />
        );
      })}
    </div>
  );
};
export default NavBarDownWeb;
